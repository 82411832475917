import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const Modal = ({ isShowing, hide, title, ...props }) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <div className="modal-overlay">
            <div className="modal-wrapper">
              <div className="modal">
                  
                <div className="modal-body">{props.children}</div>
                
                   
              </div>
              
            </div>
          </div>

          <style jsx="true">{`
            .modal-overlay {
              position: fixed;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100vh;
              z-index: 1040;
              background-color: rgba(0, 0, 0, 0.5);
            }

            .modal-wrapper {
              position: fixed;
              top: 0;
              left: 0;
              z-index: 1050;
              width: 100%;
              height: 100%;
              overflow-x: hidden;
              overflow-y: auto;
              outline: 0;
              display: flex;
              align-items: center;
            }

            .modal {
              z-index: 100;
              background: #262b32;
              position: relative;
              margin: auto;
              border-radius: 10px;
              max-width: 500px;
              width: 80%;
              height:70%;
              padding: 1rem;
            }

            .modal-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
               position: relative;
            }
            .modal-body {
              position: relative;
              height:90%;
              display:block;
            }
            .modal-button {
              position: absolute;
              top:76%;
              height:10%;
              font-size:12px;
              font-weight: 400;
              color: #C5C5C5;
              background: #454C52;
            border-radius: 10px;
            border-color: transparent;
            margin-top:10%;
            width:17%;
            }
            .modal-button:hover {
              background-color:#4DB6BC;
              color:#262B32;
            }
            .modal-close-button {
              font-size: 1.4rem;
              font-weight: 700;
              color: #000;
              cursor: pointer;
              border: none;
              background: transparent;
            }
            .ModalHeader
            {
            position: relative;
            display:initial;
            margin-left: 5%;
            width:100%;
            font-weight: 600;
            color:#4DB6BC;
            font-size:20px;}
            .Modalinput
            {
            
            width:42%;
            font-size:14px;
            color:#74787b;
            position:relative;
            background:#21272e;
            border-radius: 10px;
            border: 1px solid #8f9295; 
            height:12%;
            margin-bottom: 10px;
            margin-left: 5%;
            }
            .ModalinputS
            {
            
            width:42%;
            font-size:14px;
            color:#74787b;
            position:relative;
            background:#21272e;
            border-radius: 10px;
            border: 1px solid #8f9295; 
            height:10%;
            margin-bottom: 10px;
            margin-left: 5%;
            }
            .modalTitle
            {
            border: none;
            width:42%;
            font-size:14px;
            color:#74787b;
            position:relative;
            background:transparent; 
            margin-left: 5%;
            }
            .react-select .react-select__control {
              width:90%;
            font-size:14px;
            color:#74787b; !important
            position:relative;
            background:#21272e;
            border-radius: 10px;
            
            border: 1px solid #8f9295; 
            height:10%;
            margin-bottom: 10px;
            margin-left: 5%;
            }
            .css-1xc3v61-indicatorContainer{
              color: #8f9295;
            }
            .css-1u9des2-indicatorSeparator{
              background-color: #8f9295;
            }
            .css-d7l1ni-option{
              color: #ffffff;
              background-color: #1e88e5;
            }
            .react-select .react-select__control--is-focused {
 
  box-shadow:  0 0 1px #C5C5C5;
}
    .react-select .react-select__multi-value.css-1p3m7a8-multiValue
    {
    border-radius: 5px;
    border: 1px solid #8f9295; 
    background:#21272e;
    }
    .react-select .react-select__multi-value__label.css-wsp0cs-MultiValueGeneric
    {
    color:#74787b;
    }
    .react-select .react-select__menu.css-1nmdiq5-menu
    {
    color:#74787b;
    background:#21272e;
    }
          `}</style>
        </>,
        document.body
      )
    : null;

Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired
};

export default Modal;

import './MD.css';
import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from "../Modal/Modal";
import useModal from '../Modal/useModal';
import Modaldel from "../Modal/Modaldel";
import Cookies from 'universal-cookie';
import axios from 'axios';
import { switchlocale, localeStore, store, setName, DownloadStore, SetDownloadData, SetDownloadName, uploadStore, opt, opt1, opt_del } from "../../App.js";

let tabitems = [];
let FKbuffer = [];

function test_post_api() {
  console.log('api_test');
  fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/post_test', opt1)
}
let rowIndexselect;

let backtitle = "CHOOSE PROJECT";
const rowStyle = { backgroundColor: '#353d46', color: '#8f9295', overflow: 'hidden' };
//  white-space: nowrap;
//  text-overflow: ellipsis;

let projects = [{}];

let subprojects = [{}];

let projectsTest = { "status": "Planned", "id_project": 99, "id_contract": 4, "id_country": 108, "name_project": "Kharif 2019", "date_start": "2019-10-01T00:00:00.000Z", "date_plan_finish": "2020-03-01T00:00:00.000Z", "date_finish": "2020-03-01T00:00:00.000Z", "id_status": 3, "crops": "Cotton,Maize,Paddy", "devisions": "Panipat,Mandi,Rohtak,Ghazipur,Junagadh", "seasons": "Kharif 2019" };

// let localhost = '45.89.26.151';


function IconFormatter(cell, row) {
  let img = "images/" + cell + ".png";
  console.log(img);
  return (
    <img src={img} alt={cell} />
  );
};





function switchService(curServise) {
  if (curServise == "INV") curServise = "COM"
  else curServise = "INV"
  return curServise;
};


function nextPage(cur, max) {
  console.log(cur, max);
  if ((cur + 1) <= max) cur = cur + 1;
  console.log(cur);
  return cur;
};

function prevPage(cur) {
  if ((cur - 1) > 0) cur = cur - 1;
  console.log(cur);
  return cur;
};

function prevPagebool(cur) {
  console.log(cur)
  if (cur > 1) return false;
  else
    return true;
};

function addRow(data, row) {

  data.push(row);
  return data;
};





function editRow(data, rowData) {

  console.log("11");
  data[rowIndexselect] = rowData;
  return data;
};



const cookies = new Cookies();
let cook = cookies.get('nsi_login');

let Create_hint, Edit_hint, Delete_hint, MD_label, Date_label, Apply_btn, From_text, Search_ph, New_entry_text, Edit_entry_text, Del_text_first, Del_text_second, Entry_text, OK_btn, Cancel_btn, Delete_btn;

function SetLocaleNames() {
  let locale = localeStore.getState().locale;
  locale.forEach((name) => {
    // console.log(name);
    if (name["default_name"] == "Create_hint")
      Create_hint = name["promt_name"];
    if (name["default_name"] == "Edit_hint")
      Edit_hint = name["promt_name"];
    if (name["default_name"] == "Delete_hint")
      Delete_hint = name["promt_name"];
    if (name["default_name"] == "MD_label")
      MD_label = name["promt_name"];
    if (name["default_name"] == "Date_label")
      Date_label = name["promt_name"];
    if (name["default_name"] == "Apply_btn")
      Apply_btn = name["promt_name"];
    if (name["default_name"] == "From_text")
      From_text = name["promt_name"];
    if (name["default_name"] == "Search_ph")
      Search_ph = name["promt_name"];
    if (name["default_name"] == "Entry_text")
      Entry_text = name["promt_name"];
    if (name["default_name"] == "New_entry_text")
      New_entry_text = name["promt_name"];
    if (name["default_name"] == "Edit_entry_text")
      Edit_entry_text = name["promt_name"];
    if (name["default_name"] == "Del_text_first")
      Del_text_first = name["promt_name"];
    if (name["default_name"] == "Del_text_second")
      Del_text_second = name["promt_name"];
    if (name["default_name"] == "OK_btn")
      OK_btn = name["promt_name"];
    if (name["default_name"] == "Cancel_btn")
      Cancel_btn = name["promt_name"];
    if (name["default_name"] == "Delete_btn")
      Delete_btn = name["promt_name"];
  })
}

function App_md() {
  let permission = '';
  if (cook !== undefined) {
    // if (cook[2]!=="dgoryachev")
    permission = cook[3];
  }

  SetLocaleNames();

  let Count = -1;

  function isFKey(structFK, s, num) {
    let x = <option value={s} disabled selected>{s}</option>;
    for (let i = 0; i < structFK.length; i++) {
      if (structFK[i]["column"] == s) {
        changeRowData(structFK[i]["value"], s);
        return <select className="ModalHeader_autocombo" onChange={event => { changeRowData(event.target.value, s); }}>
          {structFK[i]["value"].map((n, i) => (
            <option value={n}  >{n}</option>
          ))}
        </select>
      }
    }
    if (num == 0 || s == "name" || s == "name_eng") {
      Count++;
      // console.log(s);
      // console.log(Count);
      // let c = Count;
      // c++;
      // setCount(Count++);
      return <input className="ModalHeader_autoinput" onChange={event => { changeRowData(event.target.value, s); confReq(Count, event.target.value) }} type="text" size="40" />
    }
    else
      return <input className="ModalHeader_autoinput" onChange={event => changeRowData(event.target.value, s)} type="text" size="40" />
  }

  function setrowData_func(dataField, value) {

    let buf = rowData;
    buf[dataField] = value;
    setrowData(buf);

  };

  function changeRowData(value, col) {
    // console.log(value + ' ' + col);

    let buf = rowData;
    buf[col] = value;
    setrowData(buf);
  }

  function inputMD(rowData) {
    let text = "'";
    Object.keys(rowData)[0] = Object.keys(rowData)[0] + "_r";
    var obj = {};
    obj[Object.keys(rowData)[0] + "_r"] = Object.values(rowData)[0];
    delete rowData[Object.keys(rowData)[0]]
    Object.assign(obj, rowData);
    for (let i = 0; i < Object.values(obj).length; i++) {
      text = text + Object.values(obj)[i] + "','";
    }

    // console.log(Object.keys(rowData));
    // console.log(Object.values(rowData));
    fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/MD_post/?schemaid=' + tabitems[active]["title_og"] + '&tableid=' + tabitems[active]["content_og"][activeCheck]["table_name"] + '&textcol=' + Object.keys(obj) + '&text=' + text.slice(0, -2), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'comp_name': 'RDMS',
      }
    }).then(
      function (response) {
        if (response.status == 200) {
          setActiveTable(true);
        }
      });

  }

  function updateMD(rowData) {
    let text = "'";
    Object.keys(rowData)[0] = Object.keys(rowData)[0] + "_r";
    var obj = {};
    obj[Object.keys(rowData)[0] + "_r"] = Object.values(rowData)[0];
    delete rowData[Object.keys(rowData)[0]]
    delete rowData["date_start"];
    delete rowData["date_end"];
    Object.assign(obj, rowData);
    for (let i = 0; i < Object.values(obj).length; i++) {
      text = text + Object.values(obj)[i] + "','";
    }
    console.log(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/MD_update/?schemaid=' + tabitems[active]["title_og"] + '&tableid=' + tabitems[active]["content_og"][activeCheck]["table_name"] + '&text=' + text.slice(0, -2) + '&upcol=' + Object.keys(obj)[0] + '&uptext=' + targetRowID + '&textcol=' + Object.keys(obj));
    fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/MD_update/?schemaid=' + tabitems[active]["title_og"] + '&tableid=' + tabitems[active]["content_og"][activeCheck]["table_name"] + '&text=' + text.slice(0, -2) + '&upcol=' + Object.keys(obj)[0] + '&uptext=' + targetRowID + '&textcol=' + Object.keys(obj), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'comp_name': 'RDMS',
      }
    }).then(
      function (response) {
        if (response.status == 200) {
          setData([]);
          setActiveTable(true);
        }
      });

  }


  function deleteMD(rowData) {

    // console.log(Object.keys(rowData));
    // console.log(Object.values(rowData));
    fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/MD_del/?schemaid=' + tabitems[active]["title_og"] + '&tableid=' + tabitems[active]["content_og"][activeCheck]["table_name"] + '&delcol=' + Object.keys(rowData)[0] + "&deltext=" + Object.values(rowData)[0], opt_del).then(
      function (response) {
        if (response.status == 200) {
          setActiveTable(true);
        }
      });

  }

  function deleteRow(event) {
    event.preventDefault();
    tableData.splice(rowIndexselect, 1);
    DownloadStore.dispatch(SetDownloadData(...tableData));
    setData([...tableData]);
  }



  const CustomToggleList = ({
    columns,
    onColumnToggle,
    toggles
  }) => (
    <div className="btn-group btn-group-toggle btn-group-vertical" data-toggle="toggle">
      {
        columns
          .map(column => ({
            ...column,
            toggle: toggles[column.dataField]
          }))
          .map(column => (
            <div className="toggleInfo">
              <input type="checkbox"
                className={`btn btn-warning ${column.toggle ? 'active' : ''}`}
                checked={column.toggle ? true : false}
                value={column.text}
                key={column.dataField}
                data-toggle="toggle"
                aria-checked={column.toggle ? false : true}
                onChange={() => onColumnToggle(column.dataField)}
              >

              </input>
              {column.text}
            </div>
          ))
      }
    </div>
  );

  const rowEvents = {

    onClick: (e, row, rowIndex) => {


      rowIndexselect = rowIndex;
      console.log(`clicked on row with index: ${rowIndex}`);
      backtitle = (row["id_project"]);
      setTitle(backtitle);
      setprojectInfo(row["id_project"]);
      setrowData(row);
      settargetRowID(row[Object.keys(rowData)[0]]);
      console.log(row["id_project"]);

    }
  };
  const { isShowing: isLoginFormShowed, toggle: toggleLoginForm } = useModal();
  const { isShowing: isDeleteFormShowed, toggle: toggleDeleteForm } = useModal();
  const { isShowing: isEditFormShowed, toggle: toggleEditForm } = useModal();
  const { isShowing: isLEditFormShowed, toggle: toggleLEditForm } = useModal();
  const { isShowing: isSubLoginFormShowed, toggle: toggleSubLoginForm } = useModal();
  const { isShowing: isSubDeleteFormShowed, toggle: toggleSubDeleteForm } = useModal();
  const { isShowing: isSubEditFormShowed, toggle: toggleSubEditForm } = useModal();
  const {
    isShowing: isRegistrationFormShowed,
    toggle: toggleRegistrationForm
  } = useModal();
  const [RperL, setRperL] = useState(1);
  const [rowData, setrowData] = useState({ "status": "", "id_project": "", "country": "", "name_project": "", "dates": "", "client": "", "service": "", "level": "", "type": "", "manager": "", "deadline": "" });
  const [backtitleH, setTitle] = useState("CHOOSE PROJECT");
  const [projectInfo, setprojectInfo] = useState("Project info");
  const [tableData, setData] = useState(projects);
  const [columns, setColumns] = useState([{}]);
  const [iscomboPrLoad, setiscomboPrLoad] = useState(true);
  const [iscomboPrLoadE, setiscomboPrLoadE] = useState(true);
  const [iscomboServiceLoad, setiscomboServiceLoad] = useState(true);
  const [iscomboServiceLoadE, setiscomboServiceLoadE] = useState(true);
  const [isLoad, setisLoad] = useState(true);
  const [tableName, setTableName] = useState('');
  const [preLoad, setpreLoad] = useState(true);
  ///
  const [targetRowID, settargetRowID] = useState('');
  const [structFK, setstructFK] = useState([]);
  ///
  const [curPage, setcurPage] = useState(1);
  const { SearchBar } = Search;
  const { ToggleList } = ColumnToggle;
  const [rowDataex, setrowDataex] = useState(null);
  const options = {
    page: curPage,
    sizePerPage: RperL,
    custom: true,
    totalSize: tableData.length
  };
  const [keyFieldBT, setkeyFieldBT] = useState("none");
  const [active, setActive] = React.useState(null);
  const [activeBox, setActiveBox] = useState(false);
  const openTab = e => { setActiveBox(true); setActive(+e.target.dataset.index); setActiveCheck(null); }
  const [subscribed, setSubscribed] = React.useState(false);
  const [activeCheck, setActiveCheck] = React.useState(null);
  const [activeTable, setActiveTable] = useState(false);
  const [datedevis, setDatedevis] = useState(null);
  const [LocLoad, setLocLoad] = useState(false);
  let initreq0 = [0];
  let initreq1 = [0, 0];
  let initreq2 = [0, 0, 0];
  const [Req, setReq] = useState([0]);
  // let [Count, setCount] = useState(-1);

  const checkTab = e => { setActiveTable(true); setActiveCheck(+e.target.dataset.index); }
  let unsubscribe = undefined;
  if (subscribed == false) {
    unsubscribe = uploadStore.subscribe(() => updateTableData());
    setSubscribed(true);
  }

  function updateTableData() {
    setData(uploadStore.getState().tableData);
    unsubscribe();
    setSubscribed(false);
    // console.log('*****');
  }

  // useEffect(() => {
  //   // if (tableData !== DownloadStore.getState().tableData) {
  //     console.log(tableData);
  //     console.log(DownloadStore.getState().tableData);
  //   // }
  // }, [DownloadStore.getState().tableData])

  useEffect(() => {
    if (isLoad) fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/nsi_schemas', opt)
      .then(response => response.text())
      .then(data => {
        let regexp = JSON.parse(data);
        tabitems = [];
        for (var i = 0; i < regexp.length; i++) {
          let sum = new Object();
          sum["title_og"] = regexp[i]["nspname"];
          localeStore.getState().locschemas.forEach((elem) => {
            if (regexp[i]["nspname"] == elem['default_name'])
              sum["title"] = elem['promt_name'];
          })
          sum["content"] = "";
          sum["content_og"] = "";
          tabitems.push(sum);
        }
        setLocLoad(true);
      })
    // console.log(tabitems);

    //const [preLoad, setpreLoad] = useState(true);
    if (preLoad) { setActiveBox(true); setActive(0); setActiveCheck(null); }
    setisLoad(false);

    if (activeBox && LocLoad) fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/schema_tables?schemaid=' + tabitems[active]["title_og"], opt)
      .then(response => response.text())
      .then(data => {
        let regexp = JSON.parse(data);
        let tmp = localeStore.getState().locschemas;
        // console.log(regexp);
        tabitems[active]["content"] = [];
        tabitems[active]["content_og"] = [];
        for (var i = 0; i < regexp.length; i++) {
          for (let n = 0; n < tmp.length; n++) {
            if (regexp[i]['table_name'] == tmp[n]['default_name']) {
              tabitems[active]["content"].push({ 'table_name': tmp[n]['promt_name'] });
              break;
            }
          }
          tabitems[active]["content_og"].push(regexp[i]);
        }
        // console.log(tabitems);
        if (preLoad) { setActiveTable(true); setActiveCheck(0); }
        setActiveBox(false);
      });

    if ((activeTable) && (tabitems[active]["content_og"][activeCheck] != null) && LocLoad) {
      let dt = '';
      if (datedevis !== null)
        dt = '&date=' + datedevis;
      // console.log(dt);
      fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/table_data?tableid=' + tabitems[active]["content_og"][activeCheck]["table_name"] + '_view&schemaid=' + tabitems[active]["title_og"] + dt, opt)
        // fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/table_data_ilo?tableid=' + tabitems[active]["content_og"][activeCheck]["table_name"] + '&schemaid=' + tabitems[active]["title_og"] + '&lang=ru'+ dt, opt)
        .then(response => response.text())
        .then(data => {
          // console.log(data);
          // console.log(data.length);
          let locc = switchlocale(cook[4]);
          // console.log(locc);
          axios.get(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/loccolumns/' + '?locale=' + locc + '&tname=' + tabitems[active]["title_og"] + '.' + tabitems[active]["content_og"][activeCheck]["table_name"], opt)
            .then(resp => {
              // console.log(resp.data)
              if (data.length > 2) {
                if (document.getElementById('centralBlock') != null) {
                  let rperl = (document.getElementById('centralBlock').clientHeight) * 0.8 / 20;
                  setRperL(Math.round(rperl - 2));
                }
                setcurPage(1);
                setData([]);
                setkeyFieldBT("none");
                let regexp = JSON.parse(data);
                let tablelist = Object.keys(regexp[0]);
                let tablemap = [];
                for (var i = 0; i < tablelist.length; i++) {
                  if (tablelist[i] == 'date_start' || tablelist[i] == 'date_end' || tablelist[i] == 'foto') {
                    continue;
                  }
                  // if ( i==0) {
                  //   tablelist[i]=tablelist[i].replace('_r','');
                  // }
                  let tmpname = '';
                  resp.data.forEach((elem) => {
                    // console.log(tablelist[i]);
                    if (tablelist[i] == elem['default_name']) {
                      tmpname = elem['promt_name'];
                    }
                  })
                  let columnbuf = new Object();
                  columnbuf["dataField"] = tablelist[i];
                  columnbuf["text"] = tmpname;//tablelist[i];
                  columnbuf["sort"] = true;
                  columnbuf["headerStyle"] = { backgroundColor: '#2B3138', width: '10%' };
                  columnbuf["style"] = {
                    whiteSpace: 'nowrap', 'overflow': 'hidden', textOverflow: 'ellipsis', maxWidth: '20px', wordBreak: 'break-all'
                    , textAlign: 'left'
                  };
                  columnbuf["editorStyle"] = { backgroundColor: 'transparent', color: '#C5C5C5' };
                  columnbuf["editor"] = { type: Type.TEXTAREA };
                  //  (i==0) ? columnbuf["isKeyProp"]=true : columnbuf["isKeyProp"]=false;  
                  tablemap.push(columnbuf);


                }
                setData(regexp);
                // console.log(regexp);
                setstructFK([]);
                if (tabitems[active]["content_og"][activeCheck]["table_name"]) {
                  let sql = "/FK/:" + tabitems[active]["title_og"] + '.:' + tabitems[active]["content_og"][activeCheck]["table_name"];

                  fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + sql, opt)
                    .then(response => response.text())
                    // .catch(error=>
                    //   {console.log(error)})
                    .then(data => {
                      if (data != 'query returned empty result') {
                        //const [structFK, setstructFK] = useState([]);
                        let FKbuf = JSON.parse(data);
                        let FKres = [];
                        if (FKbuf.length > 0) {

                          for (var i = 0; i < FKbuf.length; i++) {
                            let bufFK = new Object();
                            bufFK["column"] = FKbuf[i]["column_name"];
                            bufFK["value"] = [];
                            for (var j = 0; j < FKbuf[i]["column_value"].length; j++) {
                              bufFK["value"].push(FKbuf[i]["column_value"][j]["concat"]);
                            }
                            FKres.push(bufFK);
                          }

                        }
                        setstructFK(FKres);
                      }
                    })
                }
                console.log(regexp);
                DownloadStore.dispatch(SetDownloadData(regexp));
                setColumns(tablemap);
                setkeyFieldBT(tablemap[0]["dataField"]);
                // setCount(-1);
              }
              else {
                DownloadStore.dispatch(SetDownloadData([{}]));
                setColumns([{}]);
              }
            })
          setpreLoad(false);
          setActiveTable(false);
          setrowData({});
        });

      DownloadStore.dispatch(SetDownloadName(tabitems[active]["content_og"][activeCheck]["table_name"]));
      setTableName(tabitems[active]["content_og"][activeCheck]["table_name"]);
    }
  });

  // function clearbyDate() {
  //   console.log(datedevis)
  // }

  const TabContent = ({ title, content }) => (
    <div className="tabcontent" >
      {(content[1]) ? content.map((n, i) => (
        <button
          onClick={checkTab}
          className={`tabchecks${i === activeCheck ? '_active' : ''}`}
          data-index={i}
        ><img src={`${i === activeCheck ? "/images/bluebox.png" : "/images/shadowbox.png"}`} data-index={i} alt="boximg" role="button" />
          {"    "}{content[i]["table_name"]}</button>
      )) : console.log()}
    </div>
  );

  if (store.getState().value != MD_label) {
    store.dispatch(setName(MD_label));
  }

  function confReq(name, val) {
    console.log(Req);
    console.log("val= " + val);
    console.log("name= " + name);
    console.log("-----")
    if (/^\s*$/.test(val)) {
      const nextReq = Req.map((r, i) => {
        if (i == name) return r = 0; else return r;
      });
      setReq(nextReq);
    }
    else {
      const nextReq = Req.map((r, i) => {
        if (i == name) return r = 1; else return r;
      });
      setReq(nextReq);
    }
    // console.log(Req);
  }
  const [cap, setcap] = useState(undefined);

  let GetLocaleOptions = ['rus', 'eng', 'hi'];
  let GetLocaleLoc = switchlocale(cook[4]);

  const [IloLang, SetIloLang] = useState([]);
  const [CurLocaleLoc, SetCurLocaleLoc] = useState(GetLocaleLoc);
  useEffect(() => {
    SwitchIloLocale();
  }, [CurLocaleLoc])
  function SwitchIloLocale() {
    let TmpA = new Array;
    GetLocaleOptions.forEach((elem) => {
      if (elem != CurLocaleLoc)
        TmpA.push(0);
      else
        TmpA.push(1);
    })
    SetIloLang(TmpA);
  }

  if (IloLang.length == undefined || IloLang.length < 1)
    SwitchIloLocale();

  function ResetLocale() {
    SetCurLocaleLoc(GetLocaleLoc);
  }

  function IloButtonPushed(n) {
    if (CurLocaleLoc != n) {
      SetCurLocaleLoc(n);
      console.log(CurLocaleLoc);
      setrowData(rowData);
      // setColumns([{}]);
      New_entry_text = 'GetLocaleText';
      toggleLoginForm();
      toggleLEditForm();
    }
  }

  function IloButtonPushedE(n) {
    if (CurLocaleLoc != n) {
      SetCurLocaleLoc(n);
      console.log(CurLocaleLoc);
      setrowData(rowData);
      // setColumns([{}]);
      New_entry_text = 'GetLocaleText';
      toggleEditForm();
      toggleLEditForm();  
    }
  }

  function IloButtonPushedL(n) {
    if (CurLocaleLoc != n) {
      SetCurLocaleLoc(n);
      console.log(CurLocaleLoc);
      setrowData(rowData);
      // setColumns([{}]);
      New_entry_text = 'GetLocaleText';
      if (n==GetLocaleLoc)
      {
      toggleEditForm();
      toggleLEditForm();
      }
    }
  }

  return (

    <div className="App_md">
      <ToolkitProvider
        keyField={keyFieldBT}
        data={[...tableData]}
        columns={[...columns]}
        bootstrap4={true}
        columnToggle
        search
        exportCSV
      >
        {
          toolkitprops => (
            <PaginationProvider
              pagination={paginationFactory(options)}
            >
              {
                ({
                  paginationProps,
                  paginationTableProps
                }) => (
                  <>


                    <div className="TopInfo_md">
                      <div className="FandF_md">

                        <input value={Date_label} className="label_atd" style={{ "left": "1%" }} />

                        <input type="date" className="date_atd" style={{
                          "left": "-17%", "marginTop": "0", "marginBottom": "0",
                          "marginLeft": "0", "alignSelf": "center"
                        }} onChange={event => { setDatedevis(event.target.value) }} />


                        <button className="button_atd" style={{
                          "left": "-17%", "marginTop": "0", "marginBottom": "0", "marginRight": "2px",
                          "marginLeft": "2px", "alignSelf": "center"
                        }} onClick={() => { setActiveTable(true) }}>{Apply_btn}</button>
                        <button className="tableHeaderB_md" style={{ 'left': '-17%' }} onClick={() => { console.log(toolkitprops); console.log(paginationTableProps) }}>
                          <img src="images/find.png" alt="MD" style={{ width: '200%' }} />
                        </button>
                        <SearchBar style={{ "marginRight": "2px", 'width': '198%' }} className="FandFinput_md" placeholder={Search_ph} {...toolkitprops.searchProps} />
                      </div>
                      <div className="tab">
                        {tabitems.map((n, i) => (
                          <button
                            className={`tablinks${i === active ? '_active' : ''}`}
                            onClick={openTab}
                            data-index={i}
                          >{n.title}</button>
                        ))}
                      </div>

                    </div>

                    <div className="TopHeader_md">
                      <div className="CheckBoxGroup">
                        {tabitems[active] && <TabContent {...tabitems[active]} />}
                      </div>
                      <div className="tableHeader_md">
                        <div className="tableHeaderBGroup_md">
                          <div className="tableHeaderBonlyGroup_md">
                            {permission.indexOf('NSI_MDtableHeaderBGroup') !== -1 && (

                              <button data-hint={Create_hint} id="bt" className="tableHeaderB_md" onClick={() => {
                                toggleLoginForm();
                                setrowDataex(new Object);
                                let copy = initreq0;
                                if ((columns.filter((name) => name["dataField"] == "name").length > 0) && (columns.filter((name) => name["dataField"] == "name_eng").length > 0))
                                  copy = initreq2;
                                else if ((columns.filter((name) => name["dataField"] == "name").length > 0) || (columns.filter((name) => name["dataField"] == "name_eng").length > 0)) {
                                  copy = initreq1;
                                }
                                setReq(copy);
                                Count = -1;
                                // const nextReq = Req.map((r, i) => { return r = 0 }); setReq(nextReq);
                              }}>
                                <img src="images/Plus.png" alt="MD" />
                              </button>

                            )}
                            <Modal
                              isShowing={isLoginFormShowed}
                              hide={toggleLoginForm}>
                              {/* {setReq(initreq)} */}
                              <div className="ModalHeader">{New_entry_text}</div>
                              <br />
                              <div className="IloPanel" >
                                {
                                  GetLocaleOptions.map((n, i) => {
                                    if (IloLang[i] == 1)
                                      return (< button className="btn_lng on" id={n} onClick={() => { IloButtonPushed(n); }}> {n} </button>)
                                    else
                                      return (< button className="btn_lng" id={n} onClick={() => { IloButtonPushed(n); }}> {n} </button>)
                                  })
                                }
                              </div>
                              <div className="Modalinputs">
                                {columns.map((n, i) => (
                                  <div id={n.dataField} className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index={i}
                                    >{n.dataField}</button>
                                    {(rowDataex != undefined) ? isFKey(structFK, n.dataField, i) : ''}


                                  </div>
                                ))}
                              </div>
                              <div>
                                <button className="modal-button" onClick={() => { //console.log(Req); if (!Req.includes(0))
                                  { inputMD(rowData); toggleLoginForm(); ResetLocale(); DownloadStore.dispatch(SetDownloadData(...tableData)); setrowData({}) }
                                }} style={{ left: '52%', width: '20%', top: '86.8%', height: '12%' }}>
                                  {OK_btn}
                                </button>
                                <button className="modal-button" onClick={() => { toggleLoginForm(); ResetLocale(); }} style={{ left: '76%', width: '20%', top: '86.8%', height: '12%' }}>
                                  {Cancel_btn}
                                </button>
                              </div>
                            </Modal>
                            {permission.indexOf('NSI_MDtableHeaderBGroup') !== -1 && (
                              <button data-hint={Edit_hint} className="tableHeaderB_md" onClick={() => {
                                toggleEditForm();
                                settargetRowID(Object.values(rowData)[0]);
                                const nextReq = Req.map((r, i) => { return r = 1 }); setReq(nextReq);
                              }} >
                                <img src="images/Pen.png" alt="MD" />
                              </button>
                            )}
                            <Modal
                              isShowing={isEditFormShowed}
                              hide={toggleEditForm}>
                              {/* {setReq(initreq)} */}
                              <div className="ModalHeader">{Edit_entry_text}        </div>
                              <br />
                              <div className="IloPanel" >
                                {
                                  GetLocaleOptions.map((n, i) => {
                                    if (IloLang[i] == 1)
                                      return (< button className="btn_lng on" id={n} onClick={() => { IloButtonPushedE(n); }}> {n} </button>)
                                    else
                                      return (< button className="btn_lng" id={n} onClick={() => { IloButtonPushedE(n); }}> {n} </button>)
                                  })
                                }
                              </div>
                              <div className="Modalinputs">
                                {columns.map((n, i) => {
                                  if (i > 0) {
                                    return (
                                      <div className="ModalHeader_autodiv">
                                        <button className="ModalHeader_autobutton"
                                          data-index={i}
                                        >{n.dataField}</button>
                                        <input type='text' className="ModalHeader_autoinput" defaultValue={rowData[n.dataField]} onChange={event => { setrowData_func(n.dataField, event.target.value); }} size="40" />
                                      </div>
                                    )
                                  }
                                  else {
                                    return (
                                      <div className="ModalHeader_autodiv">
                                        <button className="ModalHeader_autobutton"
                                          data-index={i}
                                        >{n.dataField}</button>
                                        <input type='text' className="ModalHeader_autoinput" defaultValue={rowData[n.dataField]} onChange={event => { setrowData_func(n.dataField, event.target.value); setReq(0, event.target.value) }} size="40" />
                                      </div>

                                    )
                                  }
                                }
                                )}
                              </div>
                              <div>
                                <button className="modal-button" onClick={() => { if (!Req.includes(0)) { toggleEditForm(); ResetLocale(); updateMD(rowData); DownloadStore.dispatch(SetDownloadData(...tableData)); } }} style={{ left: '52%', width: '20%', top: '86.8%', height: '12%' }}>
                                  {OK_btn}
                                </button>
                                <button className="modal-button" onClick={() => { toggleEditForm(); ResetLocale(); }} style={{ left: '76%', width: '20%', top: '86.8%', height: '12%' }}>
                                  {Cancel_btn}
                                </button>
                              </div>
                            </Modal>
                            {permission.indexOf('NSI_MDtableHeaderBGroup') !== -1 && (
                              <button data-hint={Delete_hint} className="tableHeaderB_md" onClick={() => toggleDeleteForm()} >
                                <img src="images/Cancel.png" alt="MD" />
                              </button>
                            )}
                            <Modaldel style={{ width: '20%', height: '12%' }}
                              isShowing={isDeleteFormShowed}
                              hide={toggleDeleteForm}>
                              <div className="deletediv" style={{ 'height': '90%', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', 'flexDirection': 'column' }}>
                                <div className="deleteImg" style={{ 'display': 'grid', 'justifyContent': 'center', 'height': '30%' }}>
                                  <img src="images/exclam.png" alt="!" style={{ 'height': '30%' }} /></div>
                                {Del_text_first} {Entry_text}<br /><br />{Del_text_second}
                              </div>
                              <button className="modal-button" onClick={(event) => { toggleDeleteForm(); /*test_post_api()*/; deleteMD(rowData); }} style={{ left: '17%' }}>
                                {Delete_btn}
                              </button>
                              <button className="modal-button" onClick={(event) => { toggleDeleteForm(); }} style={{ left: '23%' }}>
                                {Cancel_btn}
                              </button>
                            </Modaldel>

                            <Modal
                              isShowing={isLEditFormShowed}
                              hide={toggleLEditForm}>
                              {/* {setReq(initreq)} */}
                              <div className="ModalHeader">{Edit_entry_text}        </div>
                              <br />
                              <div className="IloPanel" >
                                {
                                  GetLocaleOptions.map((n, i) => {
                                    if (IloLang[i] == 1)
                                      return (< button className="btn_lng on" id={n} onClick={() => { IloButtonPushedL(n); }}> {n} </button>)
                                    else
                                      return (< button className="btn_lng" id={n} onClick={() => { IloButtonPushedL(n); }}> {n} </button>)
                                  })
                                }
                              </div>
                              <div className="Modalinputs">
                                {columns.map((n, i) => {
                                  if (i < 3)
                                    return (
                                      <div className="ModalHeader_autodiv">
                                        <button className="ModalHeader_autobutton"
                                          data-index={i}
                                        >{n.dataField}</button>
                                        <input type='text' className="ModalHeader_autoinput" defaultValue={rowData[n.dataField]} onChange={event => { setrowData_func(n.dataField, event.target.value); }} size="40" />
                                      </div>
                                    )
                                }

                                )}
                              </div>
                              <div>
                                <button className="modal-button" onClick={() => { if (!Req.includes(0)) { toggleLEditForm(); ResetLocale(); updateMD(rowData); DownloadStore.dispatch(SetDownloadData(...tableData)); } }} style={{ left: '52%', width: '20%', top: '86.8%', height: '12%' }}>
                                  {OK_btn}
                                </button>
                                <button className="modal-button" onClick={() => { toggleLEditForm(); ResetLocale(); }} style={{ left: '76%', width: '20%', top: '86.8%', height: '12%' }}>
                                  {Cancel_btn}
                                </button>
                              </div>
                            </Modal>
                          </div>
                          <div className="tableCount_md">
                            {paginationProps.page} {From_text} {Math.ceil(paginationProps.totalSize / paginationProps.sizePerPage)}
                            <button className="tableHeaderB_count" onClick={() => { setcurPage(prevPage(curPage)); if (paginationProps.page > 1) {paginationTableProps["pagination"]["options"].onPageChange(paginationProps.page - 1); setcurPage(curPage-1) }}}>
                              {unescape("\u003c")}
                            </button>

                            <button className="tableHeaderB_count" onClick={() => { if (paginationProps.page < (Math.ceil(paginationProps.totalSize / paginationProps.sizePerPage))) { setcurPage(curPage+1);paginationTableProps["pagination"]["options"].onPageChange(paginationProps.page + 1) }}}>
                  >
                            </button>

                          </div>
                        </div>
                        <BootstrapTable headerClasses="headerclass" rowStyle={rowStyle} cellEdit={cellEditFactory({ mode: 'dbclick' })}   {...paginationTableProps} {...toolkitprops.baseProps}
                          defaultSortDirection="asc" rowEvents={rowEvents} selectRow={{
                            clickToEdit: true,
                            clickToSelect: true,
                            hideSelectColumn: true,
                            mode: 'radio',
                            style: { background: '#575859' }
                          }} />
                      </div>
                      <div className="slider">
                        <div className="content">
                          <CustomToggleList checked {...toolkitprops.columnToggleProps} columns={columns} />

                        </div>
                      </div>
                    </div>


                  </>
                )}
            </PaginationProvider>
          )
        }
      </ToolkitProvider>
    </div>


  );
}



export default App_md;

import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const Modaldel = ({ isShowing, hide, title, ...props }) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <div className="modal-overlay">
            <div className="modal-wrapper">
              <div className="modal">
                  
                <div className="modal-body">{props.children}</div>
                
                   
              </div>
              
            </div>
          </div>

          <style jsx="true">{`
            .modal-overlay {
              position: fixed;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100vh;
              z-index: 1040;
              background-color: rgba(0, 0, 0, 0.5);
            }

            .modal-wrapper {
              position: fixed;
              top: 0;
              left: 0;
              z-index: 1050;
              width: 100%;
              height: 100%;
              overflow-x: hidden;
              overflow-y: auto;
              outline: 0;
              display: flex;
              align-items: center;
            }

            .modal {
              z-index: 100;
              background: #262b32;
              position: relative;
              margin: auto;
              border-radius: 10px;
              max-width: 500px;
              width: 25%;
              height:70%;
              padding: 1rem;
            }
            .modal-body {
              position: relative;
              height:100%;
              display:block;
            }
            .modal-button {
              position: relative;
              bottom: 2%;
              height: 10%;
              font-size:12px;
              font-weight: 400;
              color: #C5C5C5;
              background: #454C52;
            border-radius: 10px;
            border-color: transparent;
            width:30%;
            }
            .modal-button:hover {
              background-color:#4DB6BC;
              color:#262B32;
            }
          `}</style>
        </>,
        document.body
      )
    : null;

Modaldel.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired
};

export default Modaldel;

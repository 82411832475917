import React from "react";
import axios from 'axios'
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { useState, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { store, setName, opt, monthNames } from '../../App.js';
import {
  VictoryChart, VictoryAxis,
  VictoryTheme, VictoryLine, VictoryLabel
} from 'victory';
import emailjs from '@emailjs/browser'

async function SendMail() {
  var templateParams = {
    to_name: 'Пользователь',
    notes: 'Тестовое сообщение',
    from_name: 'RDMS',
    email: 'firesun@mail.ru'
};

emailjs.send('service_hlg15xi','template_7bom7he', templateParams, 'ov_5XSfKBenuVTFBQ')
	.then(function(response) {
	   console.log('SUCCESS!', response.status, response.text);
	}, function(err) {
	   console.log('FAILED...', err);
	});
}

function showChart() {
  const button = document.getElementById('ShowChart');
  const button1 = document.getElementById('ShowTable');
  const tableframe = document.getElementById('tablewithoutChart');
  const mapframe = document.getElementById('ChartwithoutTable');
  if (tableframe.classList['value'] == 'tableChart hide') {
    button.style.background = '#22282F';
    button.style.color = '#C5C5C5';
    button.style.zIndex = 0;
    button1.style.background = '#4DB6BC';
    button1.style.color = '#22282F';
    button1.style.zIndex = 1;
    tableframe.style.display = 'block';
    mapframe.style.display = 'none';
  }
  else {
    button.style.background = '#4DB6BC';
    button.style.color = '#22282F';
    button.style.zIndex = 1;
    button1.style.background = '#22282F';
    button1.style.color = '#C5C5C5';
    button1.style.zIndex = 0;
    tableframe.style.display = 'none';
    mapframe.style.display = 'flex';
  }
  tableframe.classList.toggle('hide');
  // const sliderframe = document.getElementById('slider');
  // sliderframe.classList.toggle('hide');

  mapframe.classList.toggle('hide');
}

// let ComponentNumbers_fake = [123, 12, 0, 0, 5, 34, 1024,
//   25, 220, 2367, 2300, 2100, 1, 9,
//   13, 579, 1122, 30, 31, 19, 0];

function showAPI() {
  const button = document.getElementById('button_api');
  const apiframe = document.getElementById('api_table');
  apiframe.classList == 'tablemiddle' ?
    button.innerHTML = '<img src="images/arrow_down.png"/>' : button.innerHTML = '<img src="images/arrow_up.png"/>';
  apiframe.classList.toggle('hide');

}

function showLOGIN() {
  const button = document.getElementById('button_login');
  const apiframe = document.getElementById('login_table');
  apiframe.classList == 'tablemiddle' ?
    button.innerHTML = '<img src="images/arrow_down.png"/>' : button.innerHTML = '<img src="images/arrow_up.png"/>';
  apiframe.classList.toggle('hide');

}

function CompNum(a, b) {
  if (a['value'] > b['value']) return -1;
  if (a['value'] == b['value']) return 0;
  if (a['value'] < b['value']) return 1;
}

function SetDefaultDate() {
let currdate = new Date(Date.now()).toISOString().split('T')[0];
  return currdate;
}

export default function App() {
  const [api_data, setApiData] = useState([]);
  const [login_data, setLoginData] = useState([]);
  const [api_loading, setApiLoading] = useState(true);
  const [login_loading, setLoginLoading] = useState(true);
  const [api_error, setApiError] = useState(null);
  const [login_error, setLoginError] = useState(null);
  const [selectOptions, setSelectOptions] = useState(undefined);
  const [selectOptionsset, setSelectOptionsset] = useState(false);
  const [login_date, setlogin_date] = useState(undefined);
  const [api_date, setapi_date] = useState('');
  const [DiagData, SetDiagData] = useState(undefined);
  const [DiagDataOptions, SetDiagDataOptions] = useState(undefined);
  const [DiagDataisSet, SetDiagDataisSet] = useState(false);
  const [DiagDataVal, SetDiagDataVal] = useState(undefined);
  const [DateS, SetDateS] = useState(SetDefaultDate());
  const [DateE, SetDateE] = useState(SetDefaultDate());
  const [DiagDataSingle, SetDiagDataSingle] = useState(undefined);
  const [DiagDataOptionsSingle, SetDiagDataOptionsSingle] = useState(undefined);
  const [DiagDataSingleIsSet, SetDiagDataSingleIsSet] = useState(false);

  // const test_data = [
  //   { username: '1', comp_name: '2', url: '3', time: '	April 4 2023 01:41:19' },
  //   { username: '2', comp_name: '2', url: '3', time: '	Feb 3 2023 22:41:19' },
  //   { username: '3', comp_name: '2', url: '3', time: 'Jan 3 2023 22:41:19' },
  //   { username: '4', comp_name: '2', url: '3', time: '3' },
  // ]

  function MakeTR(data) {
    if (data == undefined) return;
    return data.map((item) => (
      <tr>
        <td style={{ "width": "5%", "color": "#4DB6BC",  "fontWeight": "600" }}>
          <div style={{ "width": "100%", "color": "#4DB6BC", "fontWeight": "600", "display":"flex", "justify-content":"left " }}>
          <button style={{ "width": "100%", "color": "#4DB6BC", "textAlign": "center", "fontWeight": "600" }} 
          className='mon_button' id="button_login" onClick={() => GetDiagSingleData(item)}>{item.key}</button>
          </div>
          </td>
          
        <td style={{ "width": "5%", "color": "#4DB6BC", "textAlign": "center", "fontWeight": "600" }}>{item.value}</td>
        <td className="DiagBar" key={item.key} style={{ "marginLeft":"1px", "width": item['width'] + "%" }}></td>
      </tr>
    ))
  }
// function SetElem(name){
//   DiagData
// }
  function CountResp(ret) {
    if (ret[0] == undefined) return;
    let max = ret.reduce((acc, curr) => acc.value > curr.value ? acc : curr).value;
    ret.forEach(element => {
      element['width'] = 100 * element.value / max;
      if (element['width'] == 100) {
        // console.log(element['key']);
        GetDiagSingleData(element);
      }
      if (element['width'] > 97)
        element['width'] -= 2;

    });
    return ret;
  }

  function CompareDate(a, b) {
    if (a.getFullYear() == b.getFullYear() && a.getMonth() == b.getMonth() && a.getDate() == b.getDate())
      return true;
    else
      return false;
  }

  function FormatDate(n) {
    let date = new Date(n);
    return date.getDate() + " " + monthNames[date.getMonth()];
  }

  function GetTimeDif() {
    let tmpDateS = new Date(DateS);
    let tmpDateE = new Date(DateE);
    tmpDateE.setDate(tmpDateE.getDate() + 1);
    let dif = (tmpDateE - tmpDateS) / (1000 * 60 * 60 * 24);
    let timeDif;
    let AxisData = new Object;
    let Ticks = new Array;
    let Vals = new Array;
    let hours;
    let date;
    tmpDateS = new Date(tmpDateS.setHours(0));
    tmpDateE = new Date(tmpDateE.setHours(0));
    if (dif == 1) {
      timeDif = 10 * (1000 * 60); // 10 минут
      for (let i = 0; i <= 12; i++) {
        date = new Date(tmpDateS.getTime() + (i * 2 * timeDif * 6))
        Ticks.push(date);
        if (i == 0 || i == 12) {
          Vals.push(FormatDate(tmpDateS.getTime() + (i * 2 * 1000 * 60 * 60)));
        }
        else {
          Vals.push(i * 2 + 'H');
        }
      }
    }
    else if (dif <= 4) {
      timeDif = 2 * (1000 * 60 * 60); // 2 часа
      for (let i = 0; i <= 12; i++) {
        hours = i * 2 * dif;
        date = new Date(tmpDateS.getTime() + (hours * (timeDif / 2)))
        Ticks.push(date);
        while (hours > 24) {
          hours -= 24;
        }
        if (hours == 0 || hours == 24) {
          Vals.push(FormatDate(date.getTime()));
        }
        else {
          Vals.push(hours + 'H');
        }
      }
    }
    else {
      timeDif = 6 * (1000 * 60 * 60); // 6 часов 
      let n = 0;
      if (dif > 5) {
        while (n < 100) {
          let min = dif / n;
          if (min <= 5) {
            dif = min;
            break;
          }
          n++
        }
      }
      for (let i = 0; i <= dif; i++) {
        date = new Date(tmpDateS.getTime() + (i * n * timeDif * 4));
        Ticks.push(date);
        Vals.push(FormatDate(tmpDateS.getTime() + (i * n * timeDif * 4)));
      }
    }
    AxisData['ticks'] = Ticks;
    AxisData['vals'] = Vals;
    AxisData['lable'] = 'N/A';
    // console.log(AxisData);
    // SetDiagDataOptionsSingle(AxisData);
    return [timeDif, tmpDateS, tmpDateE, AxisData];
  }

  function SortApiData(a, b) {
    if (a['id_api_use_log'] > b['id_api_use_log']) return 1;
    if (a['id_api_use_log'] == b['id_api_use_log']) return 0;
    if (a['id_api_use_log'] < b['id_api_use_log']) return -1;
  }

  function GetDiagSingleData(elem) {
    let tmdf = GetTimeDif();
    let sdate = new Date(tmdf[1]);
    let edate = new Date(tmdf[2]);
    let tmpdt = api_data.sort(SortApiData);
    let y = 0;
    let reto = new Object;
    let reta = new Array;
    let step_dif = tmdf[0]/(1000 * 60);
    tmpdt.forEach(element => {
      if (element['comp_name'] == elem['key']) {
        let date = new Date(element['time']);
        let ddif = (date - sdate) / (1000 * 60);
        if ((ddif >= -(step_dif/2) && ddif <= 0) || (ddif >= 0 && ddif <= (step_dif/2))) {
          y++;
        }
        else {
          reto['x'] = sdate;
          reto['y'] = y;
          reta.push(structuredClone(reto));
          y = 0;
          while (edate - sdate > 0) {
            sdate.setMinutes(sdate.getMinutes() + 10);
            ddif = (date - sdate) / (1000 * 60);
            if ((ddif >= -5 && ddif <= 0) || (ddif >= 0 && ddif <= 5)) {
              y = 1;
              break;
            }
            else {
              reto['x'] = sdate;
              reto['y'] = y;
              reta.push(structuredClone(reto));
            }
          }
        }
      }
    })
    reto['x'] = sdate;
    reto['y'] = y;
    reta.push(structuredClone(reto));
    while (edate - sdate > 0) {
      sdate.setMinutes(sdate.getMinutes() + 10);
      reto['x'] = sdate;
      reto['y'] = 0;
      reta.push(structuredClone(reto));
    }
    SetDiagDataSingle(reta);
tmdf[3]['lable'] = elem['key'];
    SetDiagDataOptionsSingle(tmdf[3]);
    SetDiagDataSingleIsSet(true);
  }

  function DateHandler(e) {
    console.log(e.target.value);
    if (e.target.id == 'LogDateStart') {
      SetDateS(e.target.value);
    }
    else {
      SetDateE(e.target.value);
    }
  }

  useEffect(() => {
    function MakeDiagData() {
      if (selectOptionsset == false) return;
      if (DiagDataVal == undefined) return;
      let retn = new Array;
      for (let i = 0; i < DiagDataOptions.length; i++) {
        if (DiagDataVal[DiagDataOptions[i]] != undefined) {
          let data = new Object;
          data['key'] = DiagDataOptions[i];
          data['value'] = DiagDataVal[DiagDataOptions[i]][0];
          data['width'] = DiagDataVal[DiagDataOptions[i]][1];
          retn.push(data);
        }
      }
      retn.sort(CompNum);
      SetDiagData(CountResp(retn));
      SetDiagDataisSet(true);
    }
    MakeDiagData();
  }, [DiagDataOptions, DiagDataVal])
  async function getSelectOptions() {
    let ret = new Array;
    let opts = new Object;
    await axios.get(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/table_data?tableid=class_components&schemaid=other', opt)
      .then(resp => {
        for (let i = 0; i < resp.data.length; i++) {
          ret[i] = resp.data[i].abbreviation;
        }
        ret.push('FEDVM_TEST');
        ret.push('AGROFIN_DEV');
        ret.push('AGROFIN_STAGE');
        ret.push('AGROFIN_PREPROD');
        ret.push('AGROFIN_PROD');
        ret.push('N/A');
        ret.forEach(element => {
          opts[element] = element;
        });
        setSelectOptions(opts);
        SetDiagDataOptions(ret);
        setSelectOptionsset(true);
      })
      .catch(error => {
        console.log(error);
      })
  }

  if (selectOptionsset !== true)
    getSelectOptions();

  const defaultSorted = [{
    dataField: 'id_login_log',
    order: 'desc'
  }];

  const defaultSortedA = [{
    dataField: 'id_api_use_log',
    order: 'desc'
  }];

  const columnsAPI = [
    {
      dataField: 'id_api_use_log',
      sort: true,
      isKey: true,
      text: 'id',
      hidden: true,
      filter: textFilter()
    },
    {
      dataField: 'username',
      sort: true,
      text: 'Username',
      headerStyle: { backgroundColor: '#2B3138', width: '10%', 'fontSize': '17px', border: '1px solid #8f9295' },
      style: { textAlign: 'left' },
      filter: textFilter()
    }, {
      dataField: 'comp_name',
      sort: true,
      style: { textAlign: 'left' },
      text: 'Component Name',
      headerStyle: { backgroundColor: '#2B3138', width: '10%', 'fontSize': '17px', border: '1px solid #8f9295' },
      filter: selectFilter({
        options: selectOptions
      })
    }, {
      dataField: 'url',
      sort: true,
      text: 'Url',
      headerStyle: { backgroundColor: '#2B3138', width: '10%', 'fontSize': '17px', border: '1px solid #8f9295' },
      filter: textFilter(),
      style: { textAlign: 'left' }
    }, {
      dataField: 'datelog',
      sort: false,
      text: 'Time',
      type: 'date',
      headerStyle: { backgroundColor: '#2B3138', width: '10%', 'fontSize': '17px', border: '1px solid #8f9295' },
      style: { textAlign: 'left' },
      filter: dateFilter()
    }];

  const columnsLOGIN = [{

    dataField: 'id_login_log',
    sort: true,
    isKey: true,
    text: 'id',
    hidden: true,
    filter: textFilter()
  }, {
    dataField: 'username',
    sort: true,
    text: 'Username',
    headerStyle: { backgroundColor: '#2B3138', width: '10%', 'fontSize': '17px', border: '1px solid #8f9295' },
    style: { textAlign: 'left' },
    filter: textFilter()
  }, {
    dataField: 'datelog',
    sort: false,
    text: 'Time',
    type: 'date',
    headerStyle: { backgroundColor: '#2B3138', width: '10%', 'fontSize': '17px', border: '1px solid #8f9295' },
    style: { textAlign: 'left' },
    filter: dateFilter()
  }];

  async function GetLogin() {
    // SendMail();
    try {
      const resp = await axios.get(
        process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/get_login_log?dates=' + DateS + '&datee=' + DateE
      );
      if (resp.data !== 'empty') {
        // let r_data = new Array;
        // let n = 0;
        // for (let i = 0; i < resp.data.rows.length; i++) {        //i += 2) {
        //   r_data[n] = resp.data.rows[i];
        //   r_data[n]['timelog'] = r_data[n]['timelog'].split('T')[0];
        //   n++;
        // }
        setLoginData(resp.data.rows);
        // setLoginData(r_data);
        setLoginError(null);
        setlogin_date(Date())
      }
    } catch (err) {
      console.log("err");
      setLoginError(err.message);
      setLoginData(null);
    } finally {
      setLoginLoading(false);
    }
  };

  async function GetAPI() {
    try {
      const resp = await axios.get(
        process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/get_api_use_log?dates=' + DateS + '&datee=' + DateE
      );
      if (resp.data !== 'empty') {
        console.log(resp.data)
        // let r_data = new Array;
        // let n = 0;
        // for (let i = 0; i < resp.data.rows.length; i ++) {
        //   r_data[n] = resp.data.rows[i];
        //   // r_data[n]['timelog'] = r_data[n]['timelog'].split('T')[0];
        //   console.log(r_data[n]['timelog']);
        //   n++;
        // }
        // setApiData(r_data);
        setApiData(resp.data.rows);
        // GetDiagData(r_data);
        GetDiagData(resp.data.rows);
        setApiError(null);
        setapi_date(Date());
      }
    } catch (err) {
      console.log("err");
      setApiError(err.message);
      setApiData(null);
    } finally {
      setApiLoading(false);
    }
  };

  function GetDiagData(data) {
    let tmp = new Object;
    data.forEach(element => {
      if (tmp[element['comp_name']] != undefined) {
        tmp[element['comp_name']][0]++;
      }
      else {
        tmp[element['comp_name']] = [1, 0];
      }
    });
    SetDiagDataVal(tmp);
  }

  if (store.getState().value != "Log monitoring") {
    store.dispatch(setName("Log monitoring"));
  }

  function GetY() {
    return DiagDataOptionsSingle['valsy'] / DiagDataOptionsSingle['valsx'];
  }

  function ReloadData() {
    GetAPI();
    GetLogin();
  }
  if (api_loading !== false)
    GetAPI();
  if (login_loading !== false)
    GetLogin();
  return (
    <div className="scroll-div">
      <div>
        <button className='ShowTable' id="ShowTable" onClick={() => showChart()}>Table</button>
        <button className='ShowChart' id="ShowChart" onClick={() => showChart()}>Charts</button>

        <a className='LogTimCap'>Start</a><input className='LogDate' id='LogDateStart' type='date' value={DateS} onChange={e => DateHandler(e)} />
        <a className='LogTimCap'>End</a><input className='LogDate' id='LogDateEnd' type='date' value={DateE} onChange={e => DateHandler(e)} />
        <button className='ApplyDate' id="ApplyDate" onClick={() => ReloadData()}>GO!</button>

        <div className="tableChart" id="tablewithoutChart">
          {api_loading && <div>A moment please...</div>}
          {api_error && (
            <div>{`There is a problem fetching the post data - ${api_error}`}</div>
          )}
          {(api_data && selectOptionsset) &&
            <>
              <div className='tablename'>
                <div style={{ 'display': 'inherit', 'position': 'relative' }}>
                  <h1>RDMS API call log</h1>
                  <button className='mon_button' id="button_api" onClick={() => showAPI()}><img src="images/arrow_up.png" /></button>
                  <button className='mon_refresh_button' id="button_refresh_api" onClick={() => GetAPI()}><img style={{ 'height': '28px', 'marginTop': '3px' }} src="images/RefreshButt.png" /></button>
                </div>
              </div>
              <div id="api_table" className="tablemiddle">
                {api_date &&
                  <>
                    <div style={{ "color": "#4DB6BC", "backgroundColor": "#22282F" }}> Last update {api_date}</div>
                  </>
                }
                <BootstrapTable keyField='id_api_use_log' data={api_data} columns={columnsAPI} filter={filterFactory()} defaultSorted={defaultSortedA} />

              </div>
            </>
          }
          <div>
            {login_loading && <div>A moment please...</div>}
            {login_error && (
              <div>{`There is a problem fetching the post data - ${login_error}`}</div>
            )}
            {login_data &&
              <>
                <div className='tablename'>
                  <h1>RDMS login log</h1>
                  <button className='mon_button' id="button_login" onClick={() => showLOGIN()}><img src="images/arrow_down.png" /></button>
                  <button className='mon_refresh_button' id="button_refresh_login" onClick={() => GetLogin()}><img style={{ 'height': '28px', 'marginTop': '3px' }} src="images/RefreshButt.png" /></button>
                </div>
                <div id="login_table" className="tablemiddle hide" >
                  {login_date &&
                    <>
                      <div style={{ "color": "#4DB6BC", "backgroundColor": "#22282F" }}> Last update {login_date}</div>
                    </>
                  }
                  <BootstrapTable keyField='id_login_log' data={login_data} columns={columnsLOGIN} filter={filterFactory()} defaultSorted={defaultSorted} />
                </div>

              </>
            }

          </div>
        </div>
        <div className="tableChart hide" id="ChartwithoutTable">
          {DiagDataSingleIsSet && (
            <div style={{ 'width': '60%', 'display': 'flex' }}>
              <VictoryChart
                theme={VictoryTheme.material}
              >
                <VictoryLabel text={DiagDataOptionsSingle['lable']} x={120} y={30} style={{ "fill": "#4DB6BC" }} />
                <VictoryAxis
                  // tickValues specifies both the number of ticks and where
                  // they are placed on the axis
                  style={{
                    axisLabel: { fontSize: 14, padding: 36, fill: '#4DB6BC' },
                    grid: { stroke: ({ tick }) => (tick.getHours() == 0 || tick.getHours() == 24) ? 'green' : 'grey' },
                    tickLabels: {
                      fill: ({ tick }) => (tick.getHours() == 0 || tick.getHours() == 24) ? 'green' : (tick.getHours() % 4) > 0 ? 'grey' : 'white',
                      padding: ({ tick }) => (tick.getHours() == 0 || tick.getHours() == 24) ? 20 : (tick.getHours() % 4) > 0 ? 2 : 10,
                    },
                  }}
                  tickValues={DiagDataOptionsSingle['ticks']}
                  tickFormat={DiagDataOptionsSingle['vals']}
                  label="Time"
                />
                <VictoryAxis
                  dependentAxis
                  style={{
                    axis: { stroke: "green" },
                    axisLabel: { fontSize: 14, padding: 36, fill: '#4DB6BC' },
                  }}
                  // tickFormat specifies how ticks should be displayed
                  // tickFormat={(x)=> x/DiagDataOptionsSingle['ticks']}
                  label="Number of requests"
                />
                <VictoryLine
                  style={{
                    data: { stroke: "#c43a31" },
                    parent: { border: "1px solid #ccc" }
                  }}
                  data={DiagDataSingle}
                />
              </VictoryChart>
            </div>
          )}
          {DiagDataisSet &&
            <>
              <div style={{ 'width': '40%', 'alignSelf': 'center', 'marginBottom': '17%', 'marginRight': '1%' }}>
                <table>
                  <tbody>
                    {MakeTR(DiagData)}
                  </tbody>
                </table>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
}
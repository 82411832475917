import './App.css'
import { React, useEffect, useState } from 'react';
import {
  Routes,
  Route,
  Link,
  useLocation,
  Outlet
} from "react-router-dom"
import App1 from "./components/Projects/Projects.js";
import Guide from "./components/MD/MD.js";
import AD from "./components/AD/Atd.js";
import Auth from './components/LogMon/LogMon.js';
import axios from 'axios';
import { configureStore, createSlice } from '@reduxjs/toolkit'
import { Provider, useSelector } from 'react-redux';
import { ExportToCsv } from 'export-to-csv';
import * as XLSX from 'xlsx';
import Cookies from 'universal-cookie';
import Papa from 'papaparse';
import {DateTime, Settings} from 'luxon';




// System.setProperty("user.timezone", "UTC");

const initialState = {
  value: 'Loading...',
  auth: false,
  username: '',
  locale: 'en-US'
}
const initDownloadState = {
  tableName: 0,
  tableData: 0,
  subtableName: 0,
  subtableData: 0
}
const initUploadState = {
  tableData: 0,
  subtableData: 0
}
const initLocaleState = {
  locale: '',
  locschemas: ''
}

let AD_hint, Projects_hint, MD_hint, LogMon_hint, DXLS_hint, DCSV_hint, UP_hint, USP_hint, AD_label, Projects_label, MD_label;

// const setAuth = authST => {
//   return {
//     type: 'auth/authchanged',
//     payload: authST
//   }
// }

// function onSetAuth(state = initState, action) {
//   if (action.type === 'auth/authchanged') {
//     return {
//       ...state,
//       auth: action.payload
//     }
//   }
//   return state
// }

function onSetDownloadData(state = initDownloadState, action) {
  // console.log(action.type)
  if (action.type === 'Downloaddata/Downloaddatachanged') {
    return {
      ...state,
      tableData: action.payload
    }
  }
  if (action.type === 'Downloaddata/Downloadnamechanged') {
    return {
      ...state,
      tableName: action.payload
    }
  }
  if (action.type === 'Downloaddata/DownloadSubdatachanged') {
    return {
      ...state,
      subtableData: action.payload
    }
  }
  if (action.type === 'Downloaddata/DownloadSubnamechanged') {
    return {
      ...state,
      subtableName: action.payload
    }
  }
  return state
}

const SetUploadData = uploaddata => {
  return {
    type: 'uploaddata/uploaddatachanged',
    payload: uploaddata
  }
}

const SetUploadSubData = uploaddata => {
  return {
    type: 'uploaddata/uploadSubdatachanged',
    payload: uploaddata
  }
}

function onSetUploadData(state = initUploadState, action) {
  if (action.type === 'uploaddata/uploaddatachanged') {
    return {
      ...state,
      tableData: action.payload
    }
  }
  if (action.type === 'uploaddata/uploadSubdatachanged') {
    return {
      ...state,
      subtableData: action.payload
    }
  }
  return state
}

function onSetLocaleData(state = initLocaleState, action) {

  if (action.type === 'locale/localechanged') {
    console.log(action.payload);
    return {
      ...state,
      locale: action.payload
    }
  }
  if (action.type === 'locale/locschchanged') {
    console.log(action.payload);
    return {
      ...state,
      locschemas: action.payload
    }
  }
  // console.log(state);
  return state
}

// function onSetName(state = initialState, action) {
//   if (action.type === 'doc/docnamechanged') {
//     return {
//       ...state,
//       value: action.payload
//     }
//   }
//   return state
// }

export const opt = {
  headers: {
    'Content-Type': 'application/json',
    'comp_name': 'RDMS',
  }
};

export const opt1 = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'comp_name': 'RDMS',
  }
};

export const opt_del = {
  method: 'DELETE',
  headers: {
    'Content-Type': 'application/json',
    'comp_name': 'RDMS',
  }
};
// export const setName = text => {
//   return {
//     type: 'doc/docnamechanged',
//     payload: text
//   }
// }

export const SetLocaleData = localeData => {
  console.log(localeData);
  return {
    type: 'locale/localechanged',
    payload: localeData
  }
}

export const SetLocSchemas = localeData => {
  console.log(localeData);
  return {
    type: 'locale/locschchanged',
    payload: localeData
  }
}

export const SetDownloadData = Downloaddata => {
  return {
    type: 'Downloaddata/Downloaddatachanged',
    payload: Downloaddata
  }
}

export const SetDownloadName = Downloadname => {
  return {
    type: 'Downloaddata/Downloadnamechanged',
    payload: Downloadname
  }
}

export const SetDownloadSubData = Downloaddata => {
  return {
    type: 'Downloaddata/DownloadSubdatachanged',
    payload: Downloaddata
  }
}

export const SetDownloadSubName = Downloadname => {
  return {
    type: 'Downloaddata/DownloadSubnamechanged',
    payload: Downloadname
  }
}

const nameSlice = createSlice({
  name: "name",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.value = action.payload;
    },
    setAuth: (state, action) => {
      state.auth = action.payload
    },
    setUsername: (state, action) => {
      state.username = action.payload
    }
    // SetLocale: (state, action) => {
    //   console.log("SetLocale", state, action, action.payload);
    //   state.locale = action.payload;
    //   console.log("state.locale =", state.locale);
    // }
  },
});

export const {
  setName,
  setAuth,
  setUsername,
  // SetLocale
} = nameSlice.actions;

export const store = configureStore({
  reducer: nameSlice.reducer,
});


// export const store = configureStore({ reducer: onSetName })
// export const authStore = configureStore({ reducer: onSetAuth })
export const DownloadStore = configureStore({ reducer: onSetDownloadData })
export const uploadStore = configureStore({ reducer: onSetUploadData })
export const localeStore = configureStore({ reducer: onSetLocaleData })


const selectNameValue = state => state.value
const selectAuthValue = state => state.auth


store.subscribe(() => Layout());
// localeStore.subscribe(ProtectedPage());
// localeStore.subscribe(()=>Layout());
// store.subscribe(() => SelectHandler());

// authStore.subscribe(() => App());

const cookies = new Cookies();
let cook = cookies.get('nsi_login');
if (cook !== undefined) {
  opt['headers']['Authorization'] = 'Bearer ' + cook[1];
  opt1['headers']['Authorization'] = 'Bearer ' + cook[1];
  opt_del['headers']['Authorization'] = 'Bearer ' + cook[1];
}

// console.log(cook);
let a_data = new Object();
var loc = '';

export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export default function App() {
  const [Preloaded, SetPreloaded] = useState(false);
  function PreLoad() {
    let locale = "en-US";
    if (cook !== undefined) {
      locale = cook[4];
    }
    let flocale = switchlocale(locale);
    axios.get(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/locschemas/?locale=' + flocale, opt)
      .then(response => {
        localeStore.dispatch(SetLocSchemas(response.data));
      })
    axios.get(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/loclabels/?locale=' + flocale, opt)
      .then(response => {
        localeStore.dispatch(SetLocaleData(response.data));
        response.data.forEach((name) => {
          // console.log(name);
          if (name["default_name"] == "AD_hint")
            AD_hint = name["promt_name"];
          if (name["default_name"] == "Projects_hint")
            Projects_hint = name["promt_name"];
          if (name["default_name"] == "MD_hint")
            MD_hint = name["promt_name"];
          if (name["default_name"] == "LogMon_hint")
            LogMon_hint = name["promt_name"];
          if (name["default_name"] == "DXLS_hint")
            DXLS_hint = name["promt_name"];
          if (name["default_name"] == "DCSV_hint")
            DCSV_hint = name["promt_name"];
          if (name["default_name"] == "UP_hint")
            UP_hint = name["promt_name"];
          if (name["default_name"] == "USP_hint")
            USP_hint = name["promt_name"];
          if (name["default_name"] == "AD_label")
            AD_label = name["promt_name"];
          if (name["default_name"] == "Projects_label")
            Projects_label = name["promt_name"];
          if (name["default_name"] == "MD_label")
            MD_label = name["promt_name"];
        })
        SetPreloaded(true);
        // console.log(AD_hint);
        // LocAppLabels = response.data;
        // console.log(response);
      })
      .catch(error => {
        console.log(error);
      })
  }
  console.log('12131233');
  PreLoad();
  if (Preloaded) {
    return (
      <Provider store={store}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/1" element={<PublicPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/log_mon"
              element={
                <RequireAuth>
                  <AuthPage key="AuthPage" />
                </RequireAuth>
              } />
            <Route path="/AD"
              element={
                <RequireAuth>
                  <MapPage key="MapPage" />
                </RequireAuth>
              } />
            <Route
              path="/MD"
              element={
                <RequireAuth>
                  <GuidePage key="MD" />
                </RequireAuth>
              }
            />
            <Route
              path="/main"
              element={
                <RequireAuth>
                  <ProtectedPage key="ProtectedPage" />
                </RequireAuth>
              }
            />
            <Route
              path="/*"
              element={
                <RequireAuth>
                  <GuidePage key="MD" />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </Provider>
    )
  }
}

function SelectHandler(val) {
  loc = window.location;
  let cookarray = new Array;
  let PervCook = cookies.get('nsi_login');
  for (let p = 0; p < PervCook.length; p++) {
    if (p == PervCook.length - 1)
      PervCook[p] = val;
    cookarray[p] = PervCook[p];
  }
  // console.log(cookarray);
  cookies.set('nsi_login', cookarray, { path: '/', expires: new Date(Date.now() + 1800000) });
  window.location.replace(loc);
}

// function SelectHandler1()
// {
//   console.log("SelectHandler1", store);

//   localeStore.dispatch(SetLocale(this.value));
//   // store.dispatch("", this.value);
// }
export function switchlocale(locale) {
  switch (locale) {
    case 'en-US':
      return 'eng';
    case 'ru-RU':
      return 'ru';
    case 'hi-HI':
      return 'hi';
  }
}
// let LocAppLabels = new Array;

function Layout() {
  // console.log("== name");
  const name = useSelector(state => state.value);
  // console.log(name, "== name");
  let usernamename = '';
  let permission = '';
  let locale = "en-US"
  if (cook !== undefined) {
    usernamename = cook[2];
    permission = cook[3];
    locale = cook[4];
  }


  let wbutt = document.getElementById('img_sub');
  let bbutt = document.getElementById('hoverImg_sub');
  if (name !== 'Projects') {
    if (wbutt !== null) {
      if (wbutt.classList.value == 'img')
        wbutt.classList.add('hide');
    }
    if (bbutt !== null) {
      if (bbutt.classList.value == 'hoverImg')
        bbutt.classList.add('hide');
    }
  }
  else {
    if (wbutt !== null) {
      if (wbutt.classList.value !== 'img')
        wbutt.classList.remove('hide');
    }
    if (bbutt !== null) {
      if (bbutt.classList.value !== 'hoverImg')
        bbutt.classList.remove('hide');
    }
  }

  const inputElement = document.getElementById("fileload");
  if (inputElement) {
    inputElement.addEventListener("change", handleFiles, true);
  }

  const subinputElement = document.getElementById("subfileload");
  if (subinputElement) {
    subinputElement.addEventListener("change", handlesubFiles, true);
  }

  const shpinputElement = document.getElementById("shpfileload");
  if (shpinputElement) {
    shpinputElement.addEventListener("change", handleshpFiles, true);
  }

  // const selLocElement = document.getElementById("selectlocale");
  // if (selLocElement) {
  //   selLocElement.addEventListener("change", SelectHandler1, true);
  // }

  return (
    <div className="App">
      <div className="header">
        <img className="logo" src="images/logotype.png" alt="logo" />
        <div className="UpperTitle">
          {name}
        </div>
        <div className="panel_top_right">
          <select defaultValue={locale} id="selectlocale" onChange={e => SelectHandler(e.target.value)}>
            <option value="en-US">EN</option>
            <option value="ru-RU">RU</option>
            <option value="hi-HI">HI</option>
          </select>
          <Link to="/login" >
            <button className="logout_btn">

              <div style={{ 'marginTop': '5px', 'marginRight': '5px', 'float': 'right', 'fontWeight': '600', 'color': '#4DB6BC' }}>
                {/* {localeStore.getState().locale} */}
                {usernamename}
              </div>
              <img className="logout_dude" src="images/avatar.png" />
            </button>
          </Link>
        </div>
      </div>
      <div className="mainBlock">
        <div className="leftBlock">
          <Link to="/AD" >
            <button data-hint={AD_hint} className="panelButton">
              {name === AD_label ?
                (<img className="img" style={{ "height": "20px" }} src="images/ADmapB.png" alt="ADmap" />) :
                (<img className="img" style={{ "height": "20px" }} src="images/ADmapW.png" alt="ADmap" />)
              }
              <img className="hoverImg" style={{ "height": "20px" }} src="images/ADmapB.png" alt="ADmap" />
            </button>
          </Link>
          {permission.indexOf('NSI_LeftBlock.ProjectsBtn') !== -1 && (
            <Link to="/main" >
              <button data-hint={Projects_hint} className="panelButton">
                {name === Projects_label ?
                  (<img className="img" style={{ "height": "20px" }} src="images/ProjectsB.png" alt="Projects" />) :
                  (<img className="img" style={{ "height": "20px" }} src="images/ProjectsW.png" alt="Projects" />)
                }
                <img className="hoverImg" style={{ "height": "20px" }} src="images/ProjectsB.png" alt="Projects" />
              </button>
            </Link>
          )}
          <Link to="/MD" >
            <button data-hint={MD_hint} className="panelButton">
              {name === MD_label ?
                (<img className="img" style={{ "height": "20px" }} src="images/MDB.png" alt="MD" />) :
                (<img className="img" style={{ "height": "20px" }} src="images/MDW.png" alt="MD" />)
              }
              <img className="hoverImg" style={{ "height": "20px" }} src="images/MDB.png" alt="MD" />
            </button>
          </Link>
          {permission.indexOf('NSI_LeftBlock.LogMonBtn') !== -1 && (
            <Link to="/log_mon" >
              <button data-hint={LogMon_hint} className="panelButton">
                {name === "Log monitoring" ?
                  (<img className="img" style={{ "height": "20px" }} src="images/JournalB.png" alt="Log monitoring" />) :
                  (<img className="img" style={{ "height": "20px" }} src="images/JournalW.png" alt="Log monitoring" />)
                }
                <img className="hoverImg" style={{ "height": "20px" }} src="images/JournalB.png" alt="Log monitoring" />
              </button>
            </Link>
          )
          }
        </div>
        <div className="centralBlock" id="centralBlock">
          <Outlet />
        </div>
        <div className="rightBlock">
          <button data-hint-left={DXLS_hint} className="panelButton" onClick={() => {
            var worksheet = XLSX.utils.json_to_sheet(DownloadStore.getState().tableData);
            var workbook = XLSX.utils.book_new();
            XLSX.utils.book_RDMSend_sheet(workbook, worksheet, DownloadStore.getState().tableName);
            if (name == "Projects" && DownloadStore.getState().subtableData !== 0) {
              var subworksheet = XLSX.utils.json_to_sheet(DownloadStore.getState().subtableData);
              XLSX.utils.book_RDMSend_sheet(workbook, subworksheet, DownloadStore.getState().subtableName);
            }
            XLSX.writeFile(workbook, DownloadStore.getState().tableName + ".xlsx");
          }}>
            <img className="img" src="images/downloadW.png" alt="download" />
            <img className="hoverImg" src="images/downloadB.png" alt="download" />
          </button>
          <button data-hint-left={DCSV_hint} className="panelButton" onClick={() => {
            let Coptions = {
              fieldSeparator: ',',
              quoteStrings: '"',
              decimalSeparator: '.',
              showLabels: true,
              useTextFile: false,
              useBom: true,
              useKeysAsHeaders: true,
              filename: DownloadStore.getState().tableName
            };
            let csvExporter = new ExportToCsv(Coptions);
            csvExporter.generateCsv(DownloadStore.getState().tableData);
            if (name == "Projects" && DownloadStore.getState().subtableData !== 0) {
              let subCoptions = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: DownloadStore.getState().subtableName
              };
              let csvExporter = new ExportToCsv(subCoptions);
              csvExporter.generateCsv(DownloadStore.getState().subtableData);
            }
          }}>
            <img className="img" src="images/cvsW.png" alt="cvs" />
            <img className="hoverImg" src="images/cvsB.png" alt="cvs" />
          </button>
          <input type="file" accept=".xls,.xlsx,.csv" id="fileload" style={{ display: 'none' }} />
          {/* {permission.indexOf('NSI_rightBlock.fileload') !== -1 && ( */}
            <button disabled={(name == Projects_label && permission.indexOf('NSI_tableHeaderBGroup') == -1) || 
            (name == AD_label && permission.indexOf('NSI_ATDtableHeaderBGroup') == -1 ) ||
            (name == MD_label && permission.indexOf('NSI_MDtableHeaderBGroup') == -1 )} data-hint-left={UP_hint} className="panelButton" onClick={() => document.getElementById('fileload').click()}>
              <img className="img" src="images/sqarrowW.png" alt="sqarrow" />
              <img className="hoverImg" src="images/sqarrowB.png" alt="sqarrow" />
            </button>
          {/* )} */}
          <input type="file" accept=".xls,.xlsx,.csv" id="subfileload" style={{ display: 'none' }} />
          {permission.indexOf('NSI_rightBlock.subfileload') !== -1 && (
            <button disabled={name !== Projects_label } data-hint-left={USP_hint} id='panelButton_sub' className="panelButton" onClick={() => document.getElementById('subfileload').click()}>
              <img style={{ "height": "30px" }} id='img_sub' className="img" src="images/sqarrowW_sub.png" alt="sqarrow_sub" />
              <img style={{ "height": "30px" }} id='hoverImg_sub' className="hoverImg" src="images/sqarrowB_sub.png" alt="sqarrow_sub" />
            </button>
          )}
        </div>
      </div>
    </div>
  )

}
///////////////////////////////// кнопка загрузки шейп-файлов
// {name === "Map" &&
// <>
//   <input type="file" accept=".shp,.shx,.dbf" id="shpfileload" style={{ display: 'none' }} />
//   <button className="panelButton" onClick={() => document.getElementById('shpfileload').click()}>
//     <img className="img" src="images/sqarrowW.png" alt="sqarrow" />
//     <img className="hoverImg" src="images/sqarrowB.png" alt="sqarrow" />
//   </button>
// </>
// }
async function handleFiles() {
  var reader = new FileReader();
  var filetype = this.files[0]['name'].split('.')[1];

  if (filetype == 'xls' || filetype == 'xlsx') {
    reader.readAsBinaryString(this.files[0]);

    reader.onload = (e) => {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      console.log('xlsx');
      // console.log(dataParse);
      let data_row = new Object;
      let data_in = new Array;
      let columns = new Array;
      for (let col_num = 0; col_num < dataParse[0].length; col_num++) {
        columns[col_num] = dataParse[0][col_num];
      }
      let marker = '';
      if (DownloadStore.getState().tableName == 'Projects')
        marker = "id_project";
      else
        marker = columns[0];
      for (let row_num = 1; row_num < dataParse.length; row_num++) {
        for (let row_col = 0; row_col < columns.length; row_col++) {
          if (dataParse[row_num][row_col] !== undefined)
            data_row[columns[row_col]] = dataParse[row_num][row_col];
          else
            data_row[columns[row_col]] = '';
        }
        // console.log(data_row);
        data_in.push(data_row);
        data_row = new Object;
      }
      // console.log(data_in);
      let new_tableData = DownloadStore.getState().tableData.concat(data_in);
      new_tableData.sort((a, b) => a[marker] > b[marker] ? 1 : -1);
      // console.log(new_tableData);
      for (let i = 0; i < new_tableData.length; i++) {
        for (let j = i + 1; j < new_tableData.length; j++) {
          if (new_tableData[i][marker] == new_tableData[j][marker])
            new_tableData.splice(j--, 1);
        }
      }
      // console.log('xls');
      // console.log(new_tableData);
      if (new_tableData.length > 0)
        uploadStore.dispatch(SetUploadData(new_tableData));
    }
  }
  else {
    Papa.parse(this.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // let storedata = DownloadStore.getState().tableData;
        // console.log(Object.keys(results.data[0])[0]);
        // console.log(storedata);
        let marker = '';
        if (DownloadStore.getState().tableName == 'Projects')
          marker = "id_project";
        else
          marker = Object.keys(results.data[0])[0];
        // console.log(marker);
        let new_tableData = DownloadStore.getState().tableData.concat(results.data);
        new_tableData.sort((a, b) => a[marker] > b[marker] ? 1 : -1);
        // console.log(new_tableData);
        for (let i = 0; i < new_tableData.length; i++) {
          for (let j = i + 1; j < new_tableData.length; j++) {
            if (new_tableData[i][marker] == new_tableData[j][marker])
              new_tableData.splice(j--, 1);
          }
          //  console.log(Object.keys(new_tableData[i]));
          //  console.log(Object.values(new_tableData[i]));
          Object.keys(new_tableData[i]).forEach(function (key) { if (new_tableData[i][key] == 'undefined') new_tableData[i][key] = '' });
          // console.log(Object.values(new_tableData[i]));
        }
        // console.log('xls');
        // console.log(new_tableData);
        if (new_tableData.length > 0)
          uploadStore.dispatch(SetUploadData(new_tableData));

      },
    });
  }
}


async function handlesubFiles() {
  // console.log(this.files[0]['name']);
  if (DownloadStore.getState().subtableName !== 0) {
    var reader = new FileReader();
    var filetype = this.files[0]['name'].split('.')[1];
    if (filetype == 'xls' || filetype == 'xlsx') {
      reader.readAsBinaryString(this.files[0]);
      reader.onload = (e) => {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: 'binary' });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
        console.log('xlsx');
        // console.log(dataParse);
        let data_row = new Object;
        let data_in = new Array;
        let columns = new Array;
        for (let col_num = 0; col_num < dataParse[0].length; col_num++) {
          columns[col_num] = dataParse[0][col_num];
        }
        let marker = "id_subproject";
        for (let row_num = 1; row_num < dataParse.length; row_num++) {
          for (let row_col = 0; row_col < columns.length; row_col++) {
            if (dataParse[row_num][row_col] !== undefined)
              data_row[columns[row_col]] = dataParse[row_num][row_col];
            else
              data_row[columns[row_col]] = '';
          }
          // console.log(data_row);
          data_in.push(data_row);
          data_row = new Object;
        }
        // console.log(data_in);
        let new_tableData = DownloadStore.getState().subtableData.concat(data_in);
        new_tableData.sort((a, b) => a[marker] > b[marker] ? 1 : -1);
        // console.log(new_tableData);
        for (let i = 0; i < new_tableData.length; i++) {
          for (let j = i + 1; j < new_tableData.length; j++) {
            if (new_tableData[i][marker] == new_tableData[j][marker])
              new_tableData.splice(j--, 1);
          }
        }
        // console.log('xls');
        // console.log(new_tableData);
        if (new_tableData.length > 0)
          uploadStore.dispatch(SetUploadSubData(new_tableData));
      }
    }
    else {
      Papa.parse(this.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          // let storedata = DownloadStore.getState().tableData;
          // console.log(Object.keys(results.data[0])[0]);
          // console.log(storedata);
          let marker = "id_subproject";
          console.log(results.data);
          let new_tableData = DownloadStore.getState().subtableData.concat(results.data);
          new_tableData.sort((a, b) => a[marker] > b[marker] ? 1 : -1);
          // console.log(new_tableData);
          for (let i = 0; i < new_tableData.length; i++) {
            for (let j = i + 1; j < new_tableData.length; j++) {
              if (new_tableData[i][marker] == new_tableData[j][marker])
                new_tableData.splice(j--, 1);
            }
            //  console.log(Object.keys(new_tableData[i]));
            //  console.log(Object.values(new_tableData[i]));
            Object.keys(new_tableData[i]).forEach(function (key) { if (new_tableData[i][key] == 'undefined') new_tableData[i][key] = '' });
            // console.log(Object.values(new_tableData[i]));
          }
          // console.log('xls');
          // console.log(new_tableData);
          if (new_tableData.length > 0)
            uploadStore.dispatch(SetUploadSubData(new_tableData));

        },
      });
    }
  }
}

async function handleshpFiles() {
  //   var dataset = gdal.open(this.files[0]);
  // console.log("$$ dataset $$");
  //   dataset.layers.get(0).features.forEach(function(feature) {
  //       console.log(feature.getGeometry());//.toJSON());
  //   });
}


// async function ChkAuth (tickt){
//   // console.log('111');
//   return new Promise(async(resolve,reject)=>{
//   if (cook !== undefined && cook[0] == 'authorized')
//   {
//     // console.log('222');
//     resolve(true);
//   }
//   if (tickt.indexOf('code=') !== -1) {
//     // console.log('333');
//     let tik = tickt.split('=');
//     let formData = new FormData();
//     formData.append('code', tik[1]);
//     await axios.post('http://authapidev2.class-cloud.ru/verify-code', formData)
//       .then(async (response) => {
//         if (response.data.success) {
//           const options = {
//             method: 'GET',
//             headers: {
//               'Accept': 'application/json',
//               'Authorization': 'Bearer ' + response.data.data.token
//             }
//           };
//           opt['headers']['Authorization'] = 'Bearer ' + response.data.data.token;
//           opt1['headers']['Authorization'] = 'Bearer ' + response.data.data.token;
//           await axios.get('http://authapidev2.class-cloud.ru/getuserinfo', options)
//             .then(resp => {
//               if (resp.status !== 200) {
//                 console.error(`Did not get an OK from the server. Code: ${resp.status}`);
//                 throw (resp.status);
//               }
//               else {
//                 cookies.set('nsi_login', ['authorized', response.data.data.token], { path: '/', expires: new Date(Date.now() + 1800000) });
//                 a_data['username'] = resp.data.data.username;
//                 // console.log(resp.data.data.username);
//                 let now = new Date();
//                 let day = now.getDate();
//                 let hour = now.getHours();
//                 if (hour / 10 < 1)
//                   hour = '0' + hour;
//                 let min = now.getMinutes();
//                 if (min / 10 < 1)
//                   min = '0' + min;
//                 let sec = now.getSeconds();
//                 if (sec / 10 < 1)
//                   sec = '0' + sec;
//                 a_data['time'] = monthNames[now.getMonth()] + ' ' +
//                   day + ' ' +
//                   now.getFullYear() + ' ' +
//                   hour + ':' +
//                   min + ':' +
//                   sec;
//                 axios.post('http://45.89.26.151:3001/api/log_login?username=' + a_data['username'] + '&time=' + a_data['time'], opt)
//                   .then(response => {
//                     console.log('4444');
//                     resolve(true);
//                   })
//                   .catch(error0 => {
//                     console.log("error0 " + error0);
//                   })
//               }
//             })
//             .catch(error => {
//               console.log("error1 " + error);
//               // authStore.dispatch(setAuth(false));
//               reject(false);
//             });
//         }
//       })
//       .catch(function (error) {
//         // if (error.status == 429)
//         console.log(error.status);
//         console.log("error2 " + error);
//         // authStore.dispatch(setAuth(false));
//         reject(false);
//       });
//   }
//   else
//     // authStore.dispatch(setAuth(false));
//   reject(false);
// })
// }
function RequireAuth({ children }) {
  // console.log('---1111');
  const location = useLocation();
  loc = window.location;
  let ticket = location.search;
  // if (cook == undefined)
  cook = cookies.get('nsi_login');
  if (cook !== undefined && cook[0] == 'authorized') {
    // console.log('---2222');
    // console.log(cook[2]);
    // store.dispatch(setUsername(cook[2]));
    var url = window.location.origin + window.location.pathname;
    if (ticket.indexOf('code=') !== -1) {
      window.history.replaceState({}, '', url)
      // console.log(window.location);
    }
    return children;
  }
  else {
    let permissons = '';
    if (ticket.indexOf('code=') !== -1) {
      // console.log('333');
      let tik = ticket.split('=');
      let formData = new FormData();
      formData.append('code', tik[1]);
      axios.post(process.env.REACT_APP_AWS_API_URL + 'verify-code', formData)
        .then(async (response) => {
          if (response.data.success) {
            const options = {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + response.data.data.token
              }
            };
            opt['headers']['Authorization'] = 'Bearer ' + response.data.data.token;
            opt1['headers']['Authorization'] = 'Bearer ' + response.data.data.token;
            await axios.get(process.env.REACT_APP_AWS_API_URL + 'get-permissions/NSI', options)
              .then(resp => {
                // console.log(resp);
                if (resp.status !== 200) {
                  console.error(`Did not get an OK from the server. Code: ${resp.status}`);
                  // throw (resp.status);
                  // console.log(resp)
                }
                else {
                  // console.log(resp);
                  permissons = resp.data.data.permitted;
                  console.log(permissons);
                }
              })
              .catch(error01 => {
                console.log("error01 " + error01);
              })
            await axios.get(process.env.REACT_APP_AWS_API_URL + 'getuserinfo', options)
              .then(resp => {
                if (resp.status !== 200) {
                  console.error(`Did not get an OK from the server. Code: ${resp.status}`);
                  throw (resp.status);
                }
                else {
                  cookies.set('nsi_login', ['authorized', response.data.data.token, resp.data.data.username, permissons, 'en-US'], { path: '/', expires: new Date(Date.now() + 1800000) });
                  a_data['username'] = resp.data.data.username;
                  // console.log(resp.data.data.username);
                  // let now = new Date();
                  // let day = now.getDate();
                  // let hour = now.getHours();
                  // if (hour / 10 < 1)
                  //   hour = '0' + hour;
                  // let min = now.getMinutes();
                  // if (min / 10 < 1)
                  //   min = '0' + min;
                  // let sec = now.getSeconds();
                  // if (sec / 10 < 1)
                  //   sec = '0' + sec;
                  // a_data['time'] = monthNames[now.getMonth()] + ' ' +
                  //   day + ' ' +
                  //   now.getFullYear() + ' ' +
                  //   hour + ':' +
                  //   min + ':' +
                  //   sec;
                  // a_data['time'] = new Date(Date.now()).toISOString().split('T')[1];
                  // console.log(a_data['time']);
                  a_data['date'] = new Date(Date.now()).toISOString();
                  axios.post(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/log_login?username=' + a_data['username'] /*+ '&time=' + a_data['time']*/ + '&date=' + a_data['date'], opt)
                    .then(response => {
                      // console.log(response);
                      // console.log('4444');

                      window.location.replace(loc);
                    })
                    .catch(error0 => {
                      console.log("error0 " + error0);
                    })

                }
              })
              .catch(error => {
                console.log("error1 " + error);
                // authStore.dispatch(setAuth(false));
                return;
              });
          }
        })
        .catch(function (error) {
          // if (error.status == 429)
          console.log(error.status);
          console.log("error2 " + error);
          // authStore.dispatch(setAuth(false));
          return;
        });
    }
    else {
      window.location.replace(process.env.REACT_APP_AWS_API_URL + `login.page?from=${loc}`);
      return;
    }
  }

  //   if (cook !== undefined && cook[0]!== 'authorized' && ticket.indexOf('code=') !== -1)
  //   {
  //     ChkAuth(ticket);
  // }
  // else{

  // }
}


function LoginPage() {
  window.location.replace(process.env.REACT_APP_AWS_API_URL + 'login.page');
}

function MapPage() {
  return <AD />
}

function PublicPage() {
  return (
    <ul>
      <li>
        <Link to="/login">Login</Link>
      </li>
      <li>
        <Link to="/AD">Map</Link>
      </li>
      <li>
        <Link to="/MD">MD</Link>
      </li>
      <li>
        <Link to="/main">Main</Link>
      </li>
      <li>
        <Link to="/log_mon">Log monitoring</Link>
      </li>
    </ul>
  )
}

function ProtectedPage() {
  let permission='';
  if (cook !== undefined) {
    permission = cook[3];
  }
  // console.log(permission.indexOf('NSI_LeftBlock.ProjectsBtn'));
  if(permission.indexOf('NSI_LeftBlock.ProjectsBtn')!=-1)
  return <App1 />
  else
  return <Guide />
}

function GuidePage() {
  return <Guide />
}

function AuthPage() {
  let permission='';
  if (cook !== undefined) {
    permission = cook[3];
  }
  if(permission.indexOf('NSI_LeftBlock.LogMonBtn')!=-1)
  return <Auth />
  else
  return <Guide />
}
import './Atd.css';
import ReactDOM from "react-dom";
import ColumnResizer from "column-resizer";
import React, { useRef, useState, useEffect,Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from "../Modal/Modal";
import useModal from '../Modal/useModal';
import Modaldel from "../Modal/Modaldel";
import { localeStore, store, setName, DownloadStore, SetDownloadData, SetDownloadName, uploadStore, opt, opt1 } from "../../App.js";
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import '../Map/map.css';
import axios from 'axios';
import Cookies from 'universal-cookie';

// import {opt} from './App.js';

  
let tabitems = [];
let treeDataStart = [
  {
    key: "0",
    label: "India",
    devis: "",
    id: 0,
    children: [],
    nodelist: true,
    typedev: "Country",
    codedev: "108",
    showN: false,
    parent: []
  }
];

function showMap() {
  const button = document.getElementById('button_map');
  const button1 = document.getElementById('button_table');
  const tableframe = document.getElementById('tablewithoutMap');
  if (tableframe.classList['value'] == 'tableMap hide') {
    button.style.background = '#22282F';
    button.style.color = '#C5C5C5';
    button1.style.background = '#4DB6BC';
    button1.style.color = '#22282F';
  }
  else {
    button.style.background = '#4DB6BC';
    button1.style.background = '#22282F';
    button1.style.color = '#C5C5C5';
    button.style.color = '#22282F';
  }
  tableframe.classList.toggle('hide');
  const sliderframe = document.getElementById('slider');
  sliderframe.classList.toggle('hide');
  const mapframe = document.getElementById('mapwithoutTable');
  mapframe.classList.toggle('hide');
}

function clearbyState() {
  var obj = document.getElementById("combo_gram");
  if (obj) {
    while (obj.length > 0) {
      obj.remove(0);
    }
  }
  var obj2 = document.getElementById("combo_village");
  if (obj) {
    while (obj2.length > 0) {
      obj2.remove(0);
    }
  }

}

const CustomToggleList = ({
  columns,
  onColumnToggle,
  toggles
}) => (
  <div className="btn-group btn-group-toggle btn-group-vertical" data-toggle="toggle">
    {
      columns
        .map(column => ({
          ...column,
          toggle: toggles[column.dataField]
        }))
        .map(column => (
          <div className="toggleInfo">
            <input type="checkbox"
              className={`btn btn-warning ${column.toggle ? 'active' : ''}`}
              checked={column.toggle ? true : false}
              value={column.text}
              key={column.dataField}
              data-toggle="toggle"
              aria-checked={column.toggle ? false : true}
              onChange={() => onColumnToggle(column.dataField)}
            >

            </input>
            {column.text}
          </div>
        ))
    }
  </div>
);

function clearbyStateF() {
  var obj = document.getElementById("combo_district");
  if (obj) {
    while (obj.length > 0) {
      obj.remove(0);
    }
  }

}
function clearbyDistrictF() {
  var obj = document.getElementById("combo_gram");
  if (obj) {
    while (obj.length > 0) {
      obj.remove(0);
    }
  }

}
function clearbyGPF() {
  var obj = document.getElementById("combo_village");
  if (obj) {
    while (obj.length > 0) {
      obj.remove(0);
    }
  }

}
function clearbyDistr() {
  var obj2 = document.getElementById("combo_village");
  if (obj2) {
    while (obj2.length > 0) {
      obj2.remove(0);
    }
  }
}

let typeOptions = {
  State: "State",
  District: "District",
  'Gram Panchayat': "Gram Panchayat",
  Village: "Village"
};


let shapeOptions = {
  "Not input": "Not input",
  "Draft input": "Draft input",
  "Precision input": "Precision input"
};


const test_data = [
  { code_devision: '1', type_devis: '2', url: '3', name_full: '	April ', date_start: '	April 4 2023 01:41:19', status_shape: "No input" },
  { code_devision: '2', type_devis: '2', url: '3', name_full: '	Feb ', date_start: '	April 4 2023 04:41:19' },
  { code_devision: '3', type_devis: '2', url: '3', name_full: 'Jan ', date_start: '	April 5 2023 01:41:19' },
  { code_devision: '4', type_devis: '2', url: '3', name_full: '3777', date_start: '	April 7 2023 22:41:19' }
]



const columnsATD = [{
  dataField: 'code_devision',
  isKey: true,
  sort: true,
  filter: textFilter(),
  text: 'code',
  editorStyle: { backgroundColor: 'transparent', color: '#C5C5C5' },
  editor: { type: Type.TEXTAREA },
  headerStyle: { backgroundColor: '#2B3138', width: '70px' },
  style: {
    whiteSpace: 'nowrap', 'overflow': 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all'
    , textAlign: 'left'
  },
}, {
  dataField: 'type_devis',
  sort: true,
  text: 'type_devis',
  editorStyle: { backgroundColor: 'transparent', color: '#C5C5C5' },
  editor: { type: Type.TEXTAREA },
  headerStyle: { backgroundColor: '#2B3138' },
  style: { textAlign: 'left', whiteSpace: 'nowrap', 'overflow': 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all' },
  filter: selectFilter({
    options: typeOptions
  })
}, {
  dataField: 'name_full',
  sort: true,
  text: 'name_full',
  filter: textFilter(),
  editorStyle: { backgroundColor: 'transparent', color: '#C5C5C5' },
  editor: { type: Type.TEXTAREA },
  headerStyle: { backgroundColor: '#2B3138' },
  style: { textAlign: 'left', whiteSpace: 'nowrap', 'overflow': 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all' }
}, {
  dataField: 'name_short',
  sort: true,
  text: 'name_short',
  editorStyle: { backgroundColor: 'transparent', color: '#C5C5C5' },
  editor: { type: Type.TEXTAREA },
  headerStyle: { backgroundColor: '#2B3138', width: '90px' },
  filter: textFilter(),
  style: { textAlign: 'left', whiteSpace: 'nowrap', 'overflow': 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all' }
}, {
  dataField: 'name_alt',
  sort: true,
  text: 'name_alt',
  filter: textFilter(),
  editorStyle: { backgroundColor: 'transparent', color: '#C5C5C5' },
  editor: { type: Type.TEXTAREA },
  headerStyle: { backgroundColor: '#2B3138', width: '90px' },
  style: { textAlign: 'left', whiteSpace: 'nowrap', 'overflow': 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all' }
}, {
  dataField: 'date_start',
  sort: true,
  text: 'date_start',
  filter: dateFilter(),
  editorStyle: { backgroundColor: 'transparent', color: '#C5C5C5' },
  editor: { type: Type.TEXTAREA },
  headerStyle: { backgroundColor: '#2B3138', width: '90px' },
  style: { textAlign: 'left', whiteSpace: 'nowrap', 'overflow': 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all' }
}, {
  dataField: 'date_end',
  sort: true,
  text: 'date_end',
  filter: dateFilter(),
  editorStyle: { backgroundColor: 'transparent', color: '#C5C5C5' },
  editor: { type: Type.TEXTAREA },
  headerStyle: { backgroundColor: '#2B3138', width: '90px' },
  style: { textAlign: 'left', whiteSpace: 'nowrap', 'overflow': 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all' }
}, {
  dataField: 'code_iso',
  sort: true,
  text: 'code_iso',
  filter: textFilter(),
  editorStyle: { backgroundColor: 'transparent', color: '#C5C5C5' },
  editor: { type: Type.TEXTAREA },
  headerStyle: { backgroundColor: '#2B3138', width: '80px' },
  style: { textAlign: 'left', whiteSpace: 'nowrap', 'overflow': 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all' }
}, {
  dataField: 'status_shape',
  sort: true,
  text: 'status_shape',
  editorStyle: { backgroundColor: 'transparent', color: '#C5C5C5' },
  editor: { type: Type.TEXTAREA },
  filter: selectFilter({
    options: shapeOptions
  }),
  headerStyle: { backgroundColor: '#2B3138', width: '90px' },
  style: { textAlign: 'left', whiteSpace: 'nowrap', 'overflow': 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all' }
}, {
  dataField: 'describe',
  sort: true,
  text: 'describe',
  filter: textFilter(),
  editorStyle: { backgroundColor: 'transparent', color: '#C5C5C5' },
  editor: { type: Type.TEXTAREA },
  headerStyle: { backgroundColor: '#2B3138' },
  style: { textAlign: 'left', whiteSpace: 'nowrap', 'overflow': 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all' }
}
];



let rowIndexselect;

const rowStyle = { backgroundColor: '#353d46', color: '#8f9295', overflow: 'hidden' };








function nextPage(cur, max) {
  if ((cur + 1) <= max) cur = cur + 1;
  return cur;
};

function prevPage(cur) {
  if ((cur - 1) > 0) cur = cur - 1;
  return cur;
};

function prevPagebool(cur) {
  if (cur > 1) return false;
  else
    return true;
};

function addRow(data, codeCol, typeCol, nameFCol, nameSCol, nameACol, dateSCol, dateECol, ISOCol) {
  let row = new Object();
  row['code_devision'] = codeCol;
  row['type_devis'] = typeCol;
  row['name_full'] = nameFCol;
  row['name_short'] = nameSCol;
  row['name_alt'] = nameACol;
  row['date_start'] = dateSCol;
  row['date_end'] = dateECol;
  row['code_iso'] = ISOCol;
  row['status_shape'] = 'Not Input';
  data.push(row);
  return data;
};

function setrowData_func(rowData, dataField, value) {
  rowData[dataField] = value;
  return rowData;
};



function editRow(data, rowData) {

var clone = data.slice(0);
 for (let i = 0; i < Object.values(rowData).length; i++) {
 console.log(Object.keys(rowData)[i]);
 console.log(Object.values(rowData)[i]);
     clone[rowIndexselect][Object.keys(rowData)[i]]=Object.values(rowData)[i];
    }



  return clone;
};



const cookies = new Cookies();
let cook = cookies.get('nsi_login');

let Create_hint, Edit_hint, Delete_hint, Map_btn, Table_btn, AD_label, Date_label, Apply_btn, From_text;

function SetLocaleNames() {
  let locale = localeStore.getState().locale;
  locale.forEach((name) => {
      if (name["default_name"] == "Create_hint")
      Create_hint = name["promt_name"];
      if (name["default_name"] == "Edit_hint")
        Edit_hint = name["promt_name"];
      if (name["default_name"] == "Delete_hint")
        Delete_hint = name["promt_name"];
      if (name["default_name"] == "Map_btn")
      Map_btn = name["promt_name"];
      if (name["default_name"] == "Table_btn")
      Table_btn = name["promt_name"];
      if (name["default_name"] == "AD_label")
      AD_label = name["promt_name"];
      if (name["default_name"] == "Date_label")
      Date_label = name["promt_name"];
      if (name["default_name"] == "Apply_btn")
      Apply_btn = name["promt_name"];
        if (name["default_name"] == "From_text")
        From_text = name["promt_name"];
  })
}

function App_atd() {

  let permission = '';
  if (cook !== undefined) {
    // if (cook[2]!=="dgoryachev")
    permission = cook[3];
  }

  SetLocaleNames();

  function clearbyDate() {
    var obj = document.getElementById("combo_gram");
    if (obj) {
      while (obj.length > 0) {
        obj.remove(0);
      }
    }
    var obj2 = document.getElementById("combo_village");
    if (obj2) {
      while (obj2.length > 0) {
        obj2.remove(0);
      }
    }
    var obj3 = document.getElementById("combo_district");
    if (obj3) {
      while (obj3.length > 0) {
        obj3.remove(0);
      }
    }
    var obj4 = document.getElementById("combo_state");
    if (obj4) {
      while (obj4.length > 0) {
        obj4.remove(0);
      }
      setisSLoad(true);
      setTypedevis('Country');
      setCodedevis('108');
      setisLoad(true);
      setTitle('India');
    }

  }

function searchInputJSON(json, searchValue) {
  for (var key in json) {
    if (typeof json[key] === 'object') {
      
      searchInputJSON(json[key], searchValue);
    } else if (json[key] === searchValue) {
    
      console.log(json["children"]);
      let dis='';
      if ((typeCol.split(";")[0])=='State') dis="St";
      if ((typeCol.split(";")[0])=='District') dis="Dst";
      if ((typeCol.split(";")[0])=='CD Block') dis="Blk";
      if ((typeCol.split(";")[0])=='Gram Panchayat') dis="GP";
      if ((typeCol.split(";")[0])=='Village') dis="Vil";
      if ((typeCol.split(";")[0])=='Tehsil') dis="Th";
      if ((typeCol.split(";")[0])=='SubTehsil') dis="STh";
      json["children"].push({key:searchValue+"-"+json["children"].length,label:nameFCol,children:[],codedev:codeCol,devis:dis,nodelist:false,typedev:typeCol.split(";")[0]})
    }
  }
}

function searchUpdateJSON(json, searchValue) {
  for (var key in json) {
    if (typeof json[key] === 'object') {
   
      searchUpdateJSON(json[key], searchValue);
    } else if (json[key] === searchValue) {
    
     
      console.log(typeCol);
      let dis='';
      if ((typeCol.split(";")[0])=='State') dis="St";
      if ((typeCol.split(";")[0])=='District') dis="Dst";
      if ((typeCol.split(";")[0])=='CD Block') dis="Blk";
      if ((typeCol.split(";")[0])=='Gram Panchayat') dis="GP";
      if ((typeCol.split(";")[0])=='Village') dis="Vil";
      if ((typeCol.split(";")[0])=='Tehsil') dis="Th";
      if ((typeCol.split(";")[0])=='SubTehsil') dis="STh";
      console.log(json["children"]);
      for (let i=0;i<json["children"].length;i++)
      {
      if (rowData["code_devision"]==json["children"][i]["codedev"]) {
      console.log(json["children"][i]["label"])
      if (nameFCol!='') json["children"][i]["label"]=nameFCol;
      if (dis!='') json["children"][i]["devis"]=dis;}
      }
    }
  }
}


  function inputAD() {

 
var datesInput=dateSCol;
var dateeInput=dateECol;
if (dateSCol=='') datesInput=null;
if (dateECol=='') dateeInput=null;
if ((dateSCol!=null) && (datesInput!=null)) datesInput="'"+datesInput+"'"
if ((dateECol!=null) && (dateeInput!=null)) dateeInput="'"+dateeInput+"'"
    searchInputJSON(treeData, keyTree)
    alert('&text=' +typeCol.split(";")[1]+",'"+nameFCol+"','"+codeCol+"',"+datesInput+","+dateeInput+",'"+nameSCol+"','"+nameACol+"','"+ISOCol+"'&type_devis=")
    fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/AD_post/?id_parent_area=' + Iddevis + '&text=' +typeCol.split(";")[1]+",'"+nameFCol+"','"+codeCol+"',"+datesInput+","+dateeInput+",'"+nameSCol+"','"+nameACol+"','"+ISOCol+"'&type_devis="+typeCol.split(";")[1]+"&type_parent_devis="+Idtypedevis, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'comp_name': 'RDMS',
      }
    }).then(
      function (response) {
        if (response.status == 200) {
         
        }
      });

  }
  
   
  
   function updateAD() {
    searchUpdateJSON(treeData, keyTree)
   let text="";
      for (let i = 0; i < Object.values(rowData).length; i++) {
      if (((Object.keys(rowData)[i]=="date_start") || (Object.keys(rowData)[i]=="date_end")) && (Object.values(rowData)[i]==null)) {text = text + Object.keys(rowData)[i] + "=null,";} else {
      if (Object.values(rowData)[i]==null) rowData[Object.keys(rowData)[i]]='';
      if (Object.keys(rowData)[i]=="type_devis") {
      
      
      if (typeCol.split(";")[1]) text = text + "id_type_devis="+typeCol.split(";")[1]+","; else
            {      
      if (rowData["type_devis"] == "State") text = text + "id_type_devis=1,";
      if (rowData["type_devis"] == "District") text = text + "id_type_devis=2,";
      if (rowData["type_devis"] == "Gram Panchayat") text = text + "id_type_devis=4,";

      if (rowData["type_devis"] == "Village") text = text + "id_type_devis=5,";
      if (rowData["type_devis"] == "CD Block") text = text + "id_type_devis=3,";
      if (rowData["type_devis"] == "Tehsil") text = text + "id_type_devis=6,";
      if (rowData["type_devis"] == "SubTehsil") text = text + "id_type_devis=61,";} } else
      {
       if (Object.keys(rowData)[i]=="status_shape") {
       
       if (ShapeCol.split(";")[1]) text = text + "id_status_shape="+ShapeCol.split(";")[1]+",";
       else {
       if (rowData["status_shape"] == "Not input") text = text + "id_status_shape=1,";
      if (rowData["status_shape"] == "Draft input") text = text + "id_status_shape=2,";
      if (rowData["status_shape"] == "Precision input") text = text + "id_status_shape=3,";
       }
       
       } else
      text = text + Object.keys(rowData)[i] + "='" + Object.values(rowData)[i] + "',";}}
    }
    
    fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/AD_update/?id_parent_area=' + Iddevis + "&text="+text.slice(0, -1)+"&code_devis="+typeCol.split(";")[0]+"&cur_date="+datedevis, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'comp_name': 'RDMS',
      }
    }).then(
      function (response) {
        if (response.status == 200) {
        
        setData([]);
       
        fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/allatd?' + LoadParameters, opt)
        .then(response => response.text())
        .then(data => {
          let rperl = (document.getElementById('centralBlock').clientHeight) * 0.8 / 20;
          setRperL(Math.round(rperl - 4));
          let regexp = JSON.parse(data);

          setData(regexp["ad"]);
          DownloadStore.dispatch(SetDownloadData([...tableData]));

        });
    
        }
      });

  } 
  
  
  function deleteRow(event) {
    event.preventDefault();
    tableData.splice(rowIndexselect, 1);
    DownloadStore.dispatch(SetDownloadData(...tableData));
    setData([...tableData]);
  }


  function titleByCombo(target) {
    var obj = document.getElementById(target);
    if (obj) {
      if (obj.selectedIndex != -1) {

        let draft = obj.options[obj.selectedIndex].text;
        if (target == "combo_state") {
          if (draft == 'Full country') { setTitle('India') }
          else { setTitle(draft); setTitleS(draft); }
        }
        if (target == "combo_district") {
          if (draft == 'Full state') { setTitle(titleS) }
          else { setTitle(draft); setTitleD(draft); }
        }
        if (target == "combo_gram") {
          if (draft == 'Full district') { setTitle(titleD) }
          else { setTitle(draft); setTitleG(draft); }
        }
        if (target == "combo_village") {
          if (draft == 'Full GP') { setTitle(titleG) }
          else { setTitle(draft); setTitleV(draft); }
        }

      }


    }
  }

  const rowEvents = {

//    onClick: (e, row, rowIndex) => {
//
//console.log(row["code_devision"])
//      rowIndexselect = rowIndex;
//      console.log(`clicked on row with index: ${rowIndex}`);
//      
//      setrowData(row);
//      console.log(row);
//
//
//    }
  };
  const { isShowing: isLoginFormShowed, toggle: toggleLoginForm } = useModal();
  const { isShowing: isDeleteFormShowed, toggle: toggleDeleteForm } = useModal();
  const { isShowing: isEditFormShowed, toggle: toggleEditForm } = useModal();


  const [treeData, setTreeData] = useState(treeDataStart);
  const [rowData, setrowData] = useState(undefined);
 const [keyTree, setkeyTree] = useState('');

  const [tableData, setData] = useState([{}]);

  const [isCountLoad, setisCountLoad] = useState(true);
  const [isLoad, setisLoad] = useState(true);
  const [isSLoad, setisSLoad] = useState(true);
  const [isDLoad, setisDLoad] = useState(false);
  const [isGLoad, setisGLoad] = useState(false);
  const [isVLoad, setisVLoad] = useState(false);
  const [curPage, setcurPage] = useState(1);
  const { SearchBar } = Search;
  const [rowDataex, setrowDataex] = useState(null);
  const [LoadParameters, setLoadParameters] = useState(null);


  const [codeCol, setCodeCol] = useState('');
  const [typeCol, setTypeCol] = useState('');
  const [nameFCol, setNameFCol] = useState('');
  const [nameSCol, setNameSCol] = useState('');
  const [nameACol, setNameACol] = useState('');
  const [dateSCol, setDateSCol] = useState('');
  const [dateECol, setDateECol] = useState('');
  const [ISOCol, setISOCol] = useState('');
  const [DescCol, setDescCol] = useState('');
  const [ShapeCol, setShapeCol] = useState('');

  const [state, setState] = useState('');
  const [district, setDistrict] = useState('');
  const [gram, setGram] = useState('');

  const [typedevis, setTypedevis] = useState('Country');
  const [codedevis, setCodedevis] = useState('108');
  const [Iddevis, setIddevis] = useState('');
  const [Idtypedevis, setIdTypedevis] = useState('');
  const [datedevis, setDatedevis] = useState(null);
  const [mp, setMp] = useState(null);


  const [atdcount, setAtdcount] = useState('0');
  const [title, setTitle] = useState('India');
  const [titleS, setTitleS] = useState('India');
  const [titleD, setTitleD] = useState('India');
  const [titleG, setTitleG] = useState('India');
  const [titleV, setTitleV] = useState('India');
  const [lng, setLNG] = useState(79.47158352132465);
  const [lat, setLAT] = useState(21.855180238732657);
  const [dlng, setDLNG] = useState(79.47158352132465);
  const [dlat, setDLAT] = useState(21.855180238732657);
  let initreq = [0, 0];
  const [Req, setReq] = useState(initreq);
      const [initialPos,   setInitialPos] = React.useState(null);
    const [initialSize, setInitialSize] = React.useState(null);
    
  var [zoom, setZoom] = useState(4);
  const [RperL, setRperL] = useState(1);
  const options = {
    page: curPage,
    sizePerPage: RperL,
    custom: true,
    totalSize: tableData.length
  };
  const [subscribed, setSubscribed] = useState(false);
  let unsubscribe = undefined;
  if (subscribed == false) {
    unsubscribe = uploadStore.subscribe(() => updateTableData());
    setSubscribed(true);
  }
const initial = (e) => {
        
        let resizable = document.getElementById('ComboGrid');
        
        setInitialPos(e.clientX);
        setInitialSize(resizable.offsetWidth);
        
    }
    
    const resize = (e) => {
        
        let resizable = document.getElementById('ComboGrid');
     
      let resizableadd = document.getElementById('tableMapFull');
        resizable.style.width = `${parseInt(initialSize) + parseInt(e.clientX - initialPos)}px`;
    //  resizableadd.style.width = `${parseInt(initialSize) - parseInt(e.clientX - initialPos)}px`;
    }
  function updateTableData() {
    setData(uploadStore.getState().tableData);
    unsubscribe();
    setSubscribed(false);
  }

  useEffect(() => {
    DownloadStore.dispatch(SetDownloadName(title))
  }, [title])


  useEffect(() => {
    if (isLoad) {
      let param = '0';
      if (typedevis == "Country") param = 'country=' + codedevis;
      if (typedevis == "State") param = 'ids=' + codedevis;
      if (typedevis == "District") param = 'idd=' + codedevis;
      if (typedevis == "Gram Panchayat") param = 'idgp=' + codedevis;

      if (typedevis == "Village") param = 'idv=' + codedevis;
      if (typedevis == "CD Block") param = 'idb=' + codedevis;
      if (typedevis == "Tehsil") param = 'idth=' + codedevis;
      if (typedevis == "SubTehsil") param = 'idsth=' + codedevis;


      if (datedevis != null) param = param + '&date=' + datedevis;
      if (param != '0') fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/allatd?' + param, opt)
        .then(response => response.text())
        .then(data => {
          let rperl = (document.getElementById('centralBlock').clientHeight) * 0.8 / 20;
          setRperL(Math.round(rperl - 4));
          let regexp = JSON.parse(data);
          setData(regexp["ad"]);
          DownloadStore.dispatch(SetDownloadData(regexp["ad"]));
        });
      else setData([]);
      setisLoad(false);
      setisCountLoad(true);
    }
    if (isCountLoad) {
      // const [typedevis, setTypedevis] = useState('Country');
      // const [codedevis, setCodedevis] = useState('108');
      let sql = 'indiacount/';
      let datesql = '';
      if (datedevis != null) {
        datesql = '&date=' + datedevis;
        sql = 'indiacount/?date=' + datesql;
      }
      if (typedevis == "State") sql = 'lvlcount/?ids=' + codedevis + datesql;
      if (typedevis == "District") sql = 'lvlcount/?idd=' + codedevis + datesql;
      if (typedevis == "Gram Panchayat") sql = 'lvlcount/?idgp=' + codedevis + datesql;
      if (typedevis == "Village") sql = 'lvlcount/?idv=' + codedevis + datesql;
      if (typedevis == "CD Block") sql = 'lvlcount/?idb=' + codedevis + datesql;
      if (typedevis == "Tehsil") sql = 'lvlcount/?idth=' + codedevis + datesql;
      if (typedevis == "SubTehsil") sql = 'lvlcount/?idsth=' + codedevis + datesql;
      fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/' + sql, opt)
        .then(response => response.text())
        .then(data => {
          let regexp = data.replace(/[^a-z0-9:,\s]/gi, '');
          if (regexp) setAtdcount(regexp);
        });
      setAtdcount('no one unit finded');
      setisCountLoad(false);
    }


  });

  if (store.getState().value != AD_label) {
    store.dispatch(setName(AD_label));
  }

  const mapContainer = useRef(null);
  const map = useRef(null);
  //const [zoom] = useState(6);
  const [API_KEY] = useState('aq6RCBVO2PUIAwXlkFvz');
  let td = ['idc', 'ids', 'idd', 'idb', 'idth', 'idsth', 'idgp', 'idv'];
  let zm = [4, 6, 8, 10, 12];
  let color = ['#ffffff', '#ff0000', '#00ff00', '#0000ff', '#ffff00', '#aaaa00', '#ff00ff', '#00ffff'];
  let ftd = ['Country', 'State', 'District', 'CD Block', 'Tehsil', 'Sub Tehsil', 'Gram Panchayat', 'Village', 'terget_border'];

  useEffect(() => {
    let date = '';
    if (datedevis !== null) date = '&date=' + datedevis;

    if (map.current) {
      ftd.forEach(ftd_ => {
        if (map.current.getLayer(ftd_))
          map.current.removeLayer(ftd_);
        if (map.current.getSource(ftd_))
          map.current.removeSource(ftd_);
      });
    }

    function tdswitch(typedevis) {
      switch (typedevis) {
        case 'Country':
          return td[0];
        case 'State':
          return td[1];
        case 'District':
          return td[2];
        case 'CD Block':
          return td[3];
        case 'Tehsil':
          return td[4];
        case 'Sub Tehsil':
          return td[5];
        case 'Gram Panchayat':
          return td[6];
        case 'Village':
          return td[7];
      }
    }

    function zoomswitch(typedevis) {
      switch (typedevis) {
        case 'Country':
          return zm[0];
        case 'State':
          return zm[1];
        case 'District':
          return zm[2];
        case 'CD Block':
          return zm[3];
        case 'Tehsil':
          return zm[3];
        case 'Sub Tehsil':
          return zm[3];
        case 'Gram Panchayat':
          return zm[3];
        case 'Village':
          return zm[4];
      }
    }
    function colorswitch(typedevis) {
      switch (typedevis) {
        case 'Country':
          return color[0];
        case 'State':
          return color[1];
        case 'District':
          return color[2];
        case 'CD Block':
          return color[3];
        case 'Tehsil':
          return color[4];
        case 'Sub Tehsil':
          return color[5];
        case 'Gram Panchayat':
          return color[6];
        case 'Village':
          return color[7];
      }
    }
    if (mp)
    {
    for (let i = 0; i < mp.length; i++) {
      let t_d = tdswitch(mp[i]['typedev']);
      let url_key = '?' + t_d + '=' + mp[i]['codedev'];
      axios.get(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX +`/polygon_geojson${url_key}` + date, opt)
        .then(resp => {
          if (resp.data !== 'empty' && map.current) {
            // if (map.current.getLayer(mp[i]['typedev']))
            //   map.current.removeLayer(mp[i]['typedev']);
            // if (map.current.getSource(mp[i]['typedev']))
            //   map.current.removeSource(mp[i]['typedev']);
            map.current.addSource(mp[i]['typedev'], {
              type: 'geojson',
              data: resp.data
            });
            map.current.addLayer({
              'id': mp[i]['typedev'],
              'type': 'line',
              // 'type': 'fill',
              'source': mp[i]['typedev'],
              'layout': {},
              'paint': {
                'line-color': colorswitch(mp[i]['typedev']),
                'line-width': 6,
                // 'fill-color': colorswitch(mp[i]['typedev']),
                // 'fill-opacity': 0.3,
                // 'fill-outline-color': '#ffffff'
              }
            });
            // map.current.redraw();
          }
          return;
        })
        .catch(error => {
          console.log(error);
          // return error;
        })
    }
  }
    // let t_d = tdswitch(typedevis);
    if (typedevis) {
      if (map.current && typedevis == "Country")
      {
        map.current.flyTo({
          center: [dlng, dlat],
          zoom: zm[0]
        });
        return;
      }
      let url_key = '?' + tdswitch(typedevis) + '=' + codedevis;
      axios.get(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + `/polycenter${url_key}` + date, opt)
        .then(resp => {
          if (resp.status !== 200) {
            console.error(`Did not get an OK from the server. Code: ${resp.status}`);
            throw (resp.status);
          }
          else {
            if (map.current) {
              map.current.flyTo({
                center: [resp.data.point.lon, resp.data.point.lat],
                zoom: zoomswitch(typedevis)
              });
            }
          }
          return;
        })
        .catch(error => {
          console.log(error);
          // return error;
        })


      axios.get(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + `/polygon_geojson${url_key}` + date, opt)
        .then(resp => {
          if (resp.data !== 'empty' && map.current) {
            // if (map.current.getLayer(typedevis))
            //   map.current.removeLayer(typedevis);
            // if (map.current.getSource(typedevis))
            //   map.current.removeSource(typedevis);
            map.current.addSource(typedevis, {
              type: 'geojson',
              data: resp.data
            });
            map.current.addLayer({
              'id': typedevis,
              // 'type': 'line',
              'type': 'fill',
              'source': typedevis,
              'layout': {},
              'paint': {
                // 'line-color': colorswitch(typedevis),
                // 'line-width': 6,
                'fill-color': colorswitch(typedevis),
                'fill-opacity': 0.3,
                'fill-outline-color': '#ffffff'
              }
            });
            if (map.current.getLayer('terget_border'))
              map.current.removeLayer('terget_border');
            if (map.current.getSource('terget_border'))
              map.current.removeSource('terget_border');
            map.current.addSource('terget_border', {
              type: 'geojson',
              data: resp.data
            });
            map.current.addLayer({
              'id': 'terget_border',
              'type': 'line',
              'source': 'terget_border',
              'layout': {},
              'paint': {
                'line-color': '#000000',
                'line-width': 1,
              }
            });
            // map.current.redraw();
          }
          return;
        })
        .catch(error => {
          // return error;
        })
    }
  }, [codedevis, typedevis, datedevis]);

  useEffect(() => {
    if (map.current) { return; }
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/basic-v2/style.json?key=${API_KEY}#11.2/2.55807/21.53816`,
      center: [lng, lat],
      zoom: zoom
    });
    showMap();
  });

  // if (map.current) {
  //   map.current.on('click', function (e) {
  //     // map.current.redraw();
  //   })
  // }
  let tmp_date = '';
  //------------------------------------------------Tree



  function showTreeNode(key) {
    const button = document.getElementById(key);
    if (button) {
      button.classList == 'treePlus' ?
        button.innerHTML = '<img src="images/Minus.png"/>' : button.innerHTML = '<img src="images/Plus.png" alt="MD" />';
      button.classList.toggle('minus');
    }
  }




  let treeMargin = 0;

  function Tree({ treeData }) {
    return (
      <div style={(treeMargin != "0") ? { marginLeft: "0px" } : { marginLeft: "0px" }} >
        {treeData.map((node) => (
          <TreeNode node={node} key={node.key} />

        ))}
      </div>
    );
  }

  function TreeNode({ node }) {
    let { children, label, devis, id, key, nodelist, typedev, codedev, showN, parent } = node;
    const [showChildren, setShowChildren] = useState(showN);
    const [subtreeData, setsubtreeData] = useState(treeDataStart);
    function findByIdRecursive(array, id) {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (element.key === id) {
          let sql = 'allchildatd/?id=' + element.id;
          if (datedevis != null) sql = sql + '&date=' + datedevis;
          fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/' + sql, opt)
            .then(response => response.text())
            .then(data => {
              let regexp = JSON.parse(data);
              let children = [];
              for (let i = 0; i < regexp["ad"].length; i++) {
                let childrenProps = new Object();
                let parentProps = new Object();
                childrenProps["key"] = id + "-" + i;
                childrenProps["label"] = regexp["ad"][i]["name_full"];
                childrenProps["devis"] = regexp["ad"][i]["name_devis_short"];
                childrenProps["id"] = regexp["ad"][i]["id_area"];
                childrenProps["children"] = [];
                childrenProps["typedev"] = regexp["ad"][i]["name_devis"];
                childrenProps["codedev"] = regexp["ad"][i]["code_devision"];
                childrenProps["nodelist"] = regexp["ad"][i]["exists"];
                childrenProps["showN"] = false;
                parentProps["typedev"] = array[index]["typedev"];
                parentProps["codedev"] = array[index]["codedev"];
                if (array[index]["typedev"] != "Country") childrenProps["parent"] = (array[index]["parent"]).slice(); else childrenProps["parent"] = []//array["parent"];
                if (array[index]["typedev"] != "Country") childrenProps["parent"].push(parentProps);

                children.push(childrenProps);
              }
              array[index]["children"] = children;
              array[index]["showN"] = !array[index]["showN"];
              // let tmpTree = treeData;
              // let tdata = tmpTree;
              // let s_id = id.substr(0,id.lastIndexOf("-"));
              // tmpTree

              // if (tmpTree[])
              // let data = tmpTree;
              //         let s_id = id.split("-");
              //         let p_id = 0;
              //         for(let p=1; p<=s_id.length;p++)
              //         {
              // console.log(tdata.filter(mem=> mem.key == p_id));
              // tdata = tdata.filter(mem=> mem.key == p_id)[0]["children"];
              // p_id=p_id+"-"+s_id[p];
              //         };
              //  setTreeData(treeData);
              setShowChildren(!showChildren);
              //  setisCountLoad(true);
              return children;
            })

        } else {
          if (element.children) {
            const found = findByIdRecursive(element.children, id);

            if (found) {
              return found;
            }
          }
        }
      }
    }

    const handleClickLabel = (label, codedevis, typedevis, id, parent,key) => {
      setIddevis(id);
      setMp(parent);
      setCodedevis(codedevis);
      setkeyTree(key);
      setTypedevis(typedevis);
      let param = '0';
      setTitle(label);
      if (typedevis == "Country") {param = 'country=' + codedevis; setIdTypedevis('')}
      if (typedevis == "State") {param = 'ids=' + codedevis; setIdTypedevis(1)}
      if (typedevis == "District") {param = 'idd=' + codedevis; setIdTypedevis(2)}
      if (typedevis == "Gram Panchayat") {param = 'idgp=' + codedevis; setIdTypedevis(4)}
      if (typedevis == "Village") {param = 'idv=' + codedevis; setIdTypedevis(5)}
      if (typedevis == "CD Block") {param = 'idb=' + codedevis; setIdTypedevis(3)}
      if (typedevis == "Tehsil") {param = 'idth=' + codedevis; setIdTypedevis(6)}
      if (typedevis == "SubTehsil") {param = 'idsth=' + codedevis; setIdTypedevis(61)}
      if (datedevis != null) param = param + '&date=' + datedevis;
setLoadParameters(param);
      if (param != '0') { 
      setcurPage(1);
      fetch(process.env.REACT_APP_RDMS_API_URL + ':' + process.env.REACT_APP_RDMS_API_PORT + process.env.REACT_APP_RDMS_API_PREFIX + '/allatd?' + param, opt)
        .then(response => response.text())
        .then(data => {
        if (data!="query returned empty result") {
          let rperl = (document.getElementById('centralBlock').clientHeight) * 0.8 / 20;
          setRperL(Math.round(rperl - 4));
          let regexp = JSON.parse(data);

          setData(regexp["ad"]);
          DownloadStore.dispatch(SetDownloadData(regexp["ad"]));

        }
        else setData([]);
        });}
      else setData([]);
      setisCountLoad(true);


    };

    const handleClick = () => {
      console.log("click");
      showN = !showN;
      showTreeNode(key);
      treeMargin++;
      findByIdRecursive(treeData, key);
      // setShowChildren(!showChildren);

    };
    return (
      <>

        <div style={{ display: "inline-flex" }}>
          <button id={key} className={(showN) ? "treePlus minus" : "treePlus"} onClick={() => { handleClick() }} style={(nodelist) ? { padding: "2px" } : { display: "none" }}>
            <img src={(showN) ? "images/Minus.png" : "images/Plus.png"} alt="MD" />
          </button>
          <button className="treeDevis" style={(nodelist) ? { padding: "0px" } : { paddingLeft: "25px", paddingRight: "0px" }}>
            {devis}
          </button>
          <input id={key+"_l"} defaultValue={label}  onClick={() => handleClickLabel(label, codedev, typedev, id, parent,key)} style={{ padding: "2px" }} className="label_atd" />

        </div>
        <div style={{ paddingLeft: "20px" }}>
          {showChildren && <Tree treeData={children} />}
        </div>
      </>
    );
  }


  //--------------------------------------------end of Tree


  
  function confReq(name, val) {
    if (/^\s*$/.test(val)) {
      const nextReq = Req.map((r, i) => {
        if (i == name) return r = 0; else return r;
      });
      setReq(nextReq);
    }
    else {
      const nextReq = Req.map((r, i) => {
        if (i == name) return r = 1; else return r;
      });
      setReq(nextReq);
    }
  }


  return (

    <div className="App_atd">
      <ToolkitProvider
        keyField='code_devision'
        data={[...tableData]}

        columns={columnsATD}
        bootstrap4={true}
        columnToggle
        search
        exportCSV
        
      >
        {
          toolkitprops => (
            <PaginationProvider
              pagination={paginationFactory(options)}
            >
              {
                ({
                  paginationProps,
                  paginationTableProps
                }) => (
                  <>
                    <input value={title} className="title_atd" />
                    <button className='button_show_map' id="button_map" onClick={() => showMap()}>{Map_btn}</button>
                    <button className='button_show_table' id="button_table" onClick={() => showMap()}>{Table_btn}</button>
                    <div className="central_atd">
                      <div className="ComboGrid" id="ComboGrid">
                      <div id = 'Draggable'
                draggable   = 'true'
                onDragStart = {initial} 
                onDrag      = {resize}
            />
                        <div className="ComboGridGradient">

                          <Tree treeData={[...treeData]} />
                        </div>
                        <input value={Date_label} className="label_atd" onChange={event => { tmp_date = event.target.value }} />
                        <input type="date" className="date_atd" onChange={event => { setDatedevis(event.target.value) }} />
                        <button className="button_atd" onClick={() => { clearbyDate() }}>{Apply_btn}</button>
                                    
                      </div>
                      <div className="tableMapFull" id="tableMapFull">
                        <div className="tableMap hide" id="tablewithoutMap">

                          <div className="tableHeader_atd">
                            <div className="tableHeaderBGroup_md">
                              {permission.indexOf('NSI_ATDtableHeaderBGroup') !== -1 && (
                                <button data-hint={Create_hint} className="tableHeaderB_md" onClick={() => { toggleLoginForm(); setrowDataex(new Object); }}>
                                  <img src="images/Plus.png" alt="MD" />
                                </button>
                              )}
                              <Modal
                                isShowing={isLoginFormShowed}
                                hide={toggleLoginForm}>
                                <div className="ModalHeader">Create new row</div>
                                <div className="Modalinputs" >
                                  <div id='code' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='0'
                                    >code</button>
                                    <input className="ModalHeader_autoinput" onChange={event => {setCodeCol(event.target.value); confReq(0, event.target.value);}} type="text" size="40" />


                                  </div>
                                  <div id='type_devis' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='1'
                                    >type_devis</button>
                                    <select className="ModalHeader_autocombo" style={{'width':'67%'}} onChange={event => {setTypeCol(event.target.value); confReq(1, event.target.value);}} >
                                      <option value="" disabled selected>--Select type--</option>
                                      <option value="State;1">State</option>
                                      <option value="District;2">District</option>
                                      <option value="Gram Panchayat;4">Gram Panchayat</option>
                                      <option value="Village;5">Village</option>
                                      <option value="CD Block;3">CD Block</option>
                                      <option value="Tehsil;6">Tehsil</option>
                                      <option value="SubTehsil;61">SubTehsil</option>
                                    </select>


                                  </div>
                                  <div id='name_full' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='2'
                                    >name_full</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" onChange={event => setNameFCol(event.target.value)} />


                                  </div>
                                  <div id='name_short' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='3'
                                    >name_short</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" onChange={event => setNameSCol(event.target.value)} />


                                  </div>
                                  <div id='name_alt' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='4'
                                    >name_alt</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" onChange={event => setNameACol(event.target.value)} />


                                  </div>
                                  <div id='date_start' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='5'
                                    >date_start</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" onChange={event => setDateSCol(event.target.value)} />


                                  </div>
                                  <div id='date_end' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='6'
                                    >date_end</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" onChange={event => setDateECol(event.target.value)} />


                                  </div>
                                  <div id='code_iso' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='7'
                                    >code_iso</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" onChange={event => setISOCol(event.target.value)} />


                                  </div>
                                  <div id='country' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='8'
                                    >country</button>
                                    <input className="ModalHeader_autoinput" defaultValue="India" readOnly type="text" size="40" defaulValue='India' />


                                  </div>
                                  <div id='status_shape' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='9'
                                    >status_shape</button>
                                    <input className="ModalHeader_autoinput" defaultValue="Not Input" readOnly type="text" size="40" defaulValue='Not input' />


                                  </div>
                                </div>
                                <div> 
                                  <button className="modal-button" onClick={() => { if (!Req.includes(0)) {inputAD();setData(addRow(tableData, codeCol, typeCol.split(";")[0], nameFCol, nameSCol, nameACol, dateSCol, dateECol, ISOCol)); toggleLoginForm(); DownloadStore.dispatch(SetDownloadData(...tableData)); }}} style={{ left: '52%', top: '86.8%', width: '20%', height: '12%' }}>
                                    OK
                                  </button>
                                  <button className="modal-button" onClick={() => { toggleLoginForm(); }} style={{ left: '76%', top: '86.8%', width: '20%', height: '12%' }}>
                                    Cancel
                                  </button>
                                </div>

                              </Modal>
                              {permission.indexOf('NSI_ATDtableHeaderBGroup') !== -1 && (
                                <button data-hint={Edit_hint} className="tableHeaderB_md" onClick={() => { toggleEditForm() }} >
                                  <img src="images/Pen.png" alt="MD" />
                                </button>
                              )}
                              {rowData !== undefined && (
                              <Modal
                                isShowing={isEditFormShowed}
                                hide={toggleEditForm}>
                                <div className="ModalHeader">Edit row         </div>
                                <div className="Modalinputs">

                                  <div id='code' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='0'
                                    >code</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" defaultValue={rowData['code_devision']} onChange={event => {setCodeCol(event.target.value); confReq(0, event.target.value);}}/>


                                  </div>
                                  <div id='type_devis' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='1'
                                    >type_devis</button>
                                    <select className="ModalHeader_autocombo"  style={{'width':'67%'}} defaultValue={rowData['type_devis']} onChange={event => {setTypeCol(event.target.value); confReq(1, event.target.value);}}>
                                      <option value="State;1">State</option>
                                      <option value="District;2">District</option>
                                      <option value="Gram Panchayat;4">Gram Panchayat</option>
                                      <option value="Village;5">Village</option>
                                      <option value="CD Block;3">CD Block</option>
                                      <option value="Tehsil;6">Tehsil</option>
                                      <option value="SubTehsil;61">SubTehsil</option>
                                    </select>


                                  </div>
                                  <div id='name_full' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='2'
                                    >name_full</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" defaultValue={rowData['name_full']} onChange={event =>{ setNameFCol(event.target.value);rowData['name_full']=event.target.value}}/>


                                  </div>
                                  <div id='name_short' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='3'
                                    >name_short</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" defaultValue={rowData['name_short']} onChange={event => {setNameSCol(event.target.value);rowData['name_short']=event.target.value}}/>


                                  </div>
                                  <div id='name_alt' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='4'
                                    >name_alt</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" defaultValue={rowData['name_alt']} onChange={event => {setNameACol(event.target.value);rowData['name_alt']=event.target.value}}/>


                                  </div>
                                  <div id='date_start' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='5'
                                    >date_start</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" defaultValue={rowData['date_start']} onChange={event => {setDateSCol(event.target.value);rowData['date_start']=event.target.value}}/>


                                  </div>
                                  <div id='date_end' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='6'
                                    >date_end</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" defaultValue={rowData['date_end']} onChange={event => {setDateECol(event.target.value);rowData['date_end']=event.target.value}}/>


                                  </div>
                                  <div id='code_iso' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='7'
                                    >code_iso</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" defaultValue={rowData['code_iso']} onChange={event => {setISOCol(event.target.value);rowData['code_iso']=event.target.value}}/>


                                  </div>
                                  <div id='status_shape' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='8'
                                    >status_shape</button>
                                    <select className="ModalHeader_autocombo" style={{'width':'67%'}} defaultValue={rowData['status_shape']} onChange={event => setShapeCol(event.target.value)}>
                                      <option value="Not input;1">Not input</option>
                                      <option value="Draft input;2">Draft input</option>
                                      <option value="Precision input;3">Precision input</option>
                                    </select>



                                  </div>
                                    <div id='country' className="ModalHeader_autodiv">
                                    <button className="ModalHeader_autobutton"

                                      data-index='9'
                                    >country</button>
                                    <input className="ModalHeader_autoinput" type="text" size="40" defaultValue='India' />


                                  </div>
                                </div>
                                <div>
                                  <button className="modal-button" onClick={() => {   updateAD(); toggleEditForm();  }} style={{ left: '52%', width: '20%', top: '86.8%', height: '12%' }}>
                                    OK
                                  </button>
                                  <button className="modal-button" onClick={() => { toggleEditForm(); }} style={{ left: '76%', width: '20%', top: '86.8%', height: '12%' }}>
                                    Cancel
                                  </button>
                                </div>
                              </Modal>
                              )}
                              {permission.indexOf('NSI_ATDtableHeaderBGroup') !== -1 && (
                                <button data-hint={Delete_hint} className="tableHeaderB_md" onClick={() => toggleDeleteForm()}>
                                  <img src="images/Cancel.png" alt="MD" />
                                </button>
                              )}
                              {rowData !== undefined && (
                              <Modaldel style={{ width: '20%', height: '12%' }}
                                isShowing={isDeleteFormShowed}
                                hide={toggleDeleteForm}>
                                  
                                <div className="deletediv" style={{'height':'90%', 'display':'flex', 'align-items':'center', 'justify-content':'center', 'flex-direction':'column'}}>
                                <div className="deleteImg" style={{'display':'grid', 'justify-content':'center', 'height':'30%'}}>
                                    <img src="images/exclam.png" alt="!"style={{'height':'30%'}} /></div>
                                  You want to delete row<br /><br />Are you sure?
                                </div>
                                <button className="modal-button" onClick={(event) => { toggleDeleteForm(); deleteRow(event); }} style={{ left: '17%'}}>
                                  Delete
                                </button>
                                <button className="modal-button" onClick={(event) => { toggleDeleteForm(); }} style={{ left: '23%'}}>
                                  Cancel
                                </button>
                              </Modaldel>
                              )}
                              <div className="tableCount_md">
                                {paginationProps.page} {From_text} {Math.ceil(paginationProps.totalSize / paginationProps.sizePerPage)}
                                <button className="tableHeaderB_count" onClick={() => { if (paginationProps.page > 1) { setcurPage(curPage-1); paginationTableProps["pagination"]["options"].onPageChange(paginationProps.page - 1)  }}}>
                                  {unescape("\u003c")}
                                </button>

                                <button className="tableHeaderB_count" onClick={() => {  if (paginationProps.page < (Math.ceil(paginationProps.totalSize / paginationProps.sizePerPage))) {setcurPage(curPage+1); paginationTableProps["pagination"]["options"].onPageChange(paginationProps.page + 1) }}}>
                  >
                                </button>
                              </div>
                            </div>


                          </div>
                          <BootstrapTable  id="table" striped headerClasses="headerclass" rowStyle={rowStyle} cellEdit={cellEditFactory({ mode: 'dbclick' })}   {...paginationTableProps} {...toolkitprops.baseProps} filter={filterFactory()}
                            defaultSortDirection="asc" rowEvents={rowEvents} selectRow={{
                              
                              clickToSelect: true,
                              hideSelectColumn: true,
                              mode: 'radio',
                              onSelect: (row, isSelect, rowIndex, e) => {
                              setrowData(row);
   console.log(`clicked on row with index: ${rowIndex}`);
 },
                              style: { background: '#575859' }
                            }} />


                        </div>
                        <div className="tableMap " id="mapwithoutTable" style={{ background: 'green' }}>
                          <div className="map-wrap">
                            <div ref={mapContainer} className="map" />
                          </div>
                        </div>
                        <div className="count_atd">
                          This AD unit contains: {atdcount}
                        </div>
                      </div>
                    </div>
                    <div className="slider hide" id='slider' style={{'top':'6em'}}>
                      <div className="content">
                        <CustomToggleList checked {...toolkitprops.columnToggleProps} columns={columnsATD} />
                      </div>
                    </div>
                  </>
                )}
            </PaginationProvider>
          )
        }
      </ToolkitProvider>

    </div>

  );
}



export default App_atd;
